import { createSlice } from '@reduxjs/toolkit'
import { API } from 'aws-amplify'

const initialState = {
  menus: [],
  products: [],
  isLoading: false,
  error: {},
}

const menuBuilderSlice = createSlice({
  name: 'menus',
  initialState,
  reducers: {
    setIsLoading: (state, action) => {
      state.isLoading = action.payload
    },
    setMenus: (state, action) => {
      state.menus = action.payload
    },
    setProducts: (state, action) => {
      state.products = action.payload.reduce((accumulator, currentItem) => {
        // Check if the current item's _id is already in the accumulator
        if (!accumulator.some(item => item._id === currentItem._id)) {
          // If not, add it to the accumulator
          accumulator.push(currentItem)
        }
        return accumulator
      }, [])
    },
    setError: (state, action) => {
      state.error = action.payload
    },
  },
})

export const fetchAllMenus = venueId => async dispatch => {
  dispatch(setIsLoading(true))
  try {
    const data = await API.get('api2', `menus/${venueId}`)
    dispatch(setMenus(data?.menus))
    dispatch(setIsLoading(false))
  } catch (error) {
    dispatch(setError(error))
    dispatch(setIsLoading(false))
  }
}

export const deleteMenu = (menuId, venueId) => async dispatch => {
  dispatch(setIsLoading(true))
  try {
    await API.del('api2', `menus/${menuId}`)
    await dispatch(fetchAllMenus(venueId))
  } catch (error) {
    dispatch(setError(error))
  }
}
export const getProducts = venueId => async dispatch => {
  try {
    const products = await API.get('api', `venue/${venueId}/products`)
    dispatch(setProducts(products))
  } catch (error) {
    dispatch(setError(error))
  }
}
// export const createMenuItem = (venueId, formData) => async dispatch => {
//   setIsLoading(true)
//   try {
//     const session = await Auth.currentSession();
//     const token = session.getAccessToken().getJwtToken();

//     await fetch(`${config.apiGateway.EXPRESS_SERVER_URL}menus/createMenuItem`, {
//       method: 'POST',
//       body: formData,
//       headers: {
//         'Content-Type': 'multipart/form-data',
//         'Authorization': `${token}`,
//       },
//     })
//     await dispatch(fetchAllMenus(venueId))
//     setIsLoading(false)
//   } catch (error) {
//     dispatch(setError(error))
//   }
// }

export const { setIsLoading, setMenus, setError, setProducts } =
  menuBuilderSlice.actions

export default menuBuilderSlice
