import { Input, Select, Switch } from 'antd'
import React, { useState } from 'react'
import { isEmail, ReactMultiEmail } from 'react-multi-email'
import styled from 'styled-components/macro'

const EditAccountModal = ({
  account,
  setEditingAccount,
  suppliers,
  allVenues,
}) => {
  const { Option } = Select
  const {
    name,
    venues,
    creditApproved,
    creditLimit,
    creditDays,
    TFRAdmin,
    supplierAdmin,
    AIOrderCapture,
    AIOrderCaptureEmail,
  } = account
  const [showSupplierDropDown, setShowSupplierDropDown] = useState(false)

  const handleSelectCreditChange = event => {
    setEditingAccount({
      ...account,
      creditApproved: event,
    })
  }

  const handleSelectVenuesChange = event => {
    setEditingAccount({
      ...account,
      venues: event,
    })
  }

  const creditOptions = suppliers
    .filter(i => i.allowsCreditPurchase)
    .map(supplier => (
      <Option key={supplier._id}>
        {supplier.title}&nbsp;{supplier.secondTitle}
      </Option>
    ))

  const venueOptions = allVenues.map(venue => (
    <Option key={venue._id}>{venue.title}</Option>
  ))

  const supplierOptions = suppliers.map(supplier => (
    <Option key={supplier._id} value={supplier._id}>
      {supplier.title} &nbsp; {supplier.secondTitle}
    </Option>
  ))

  const groupSuppliersByTitle = suppliers => {
    return suppliers.reduce((acc, supplier) => {
      if (!acc[supplier.title]) {
        acc[supplier.title] = []
      }
      acc[supplier.title].push(supplier._id)
      return acc
    }, {})
  }

  const supplierGroups = groupSuppliersByTitle(suppliers)

  const handleSupplierChange = value => {
    const allSelectedSuppliers = value.reduce((acc, supplierId) => {
      const supplier = suppliers.find(s => s._id === supplierId)
      const group = supplierGroups[supplier.title]
      return [...new Set([...acc, ...group])]
    }, [])

    setEditingAccount({
      ...account,
      supplier: allSelectedSuppliers,
    })
  }

  return (
    <Wrapper>
      <Row>
        <Input
          value={name}
          onChange={event => {
            setEditingAccount({ ...account, name: event.target.value })
          }}
          addonBefore='Name'
          placeholder='John Smith'
        />
      </Row>
      <Row>
        <Div>
          <Span>Second Email Addresses</Span>
          <ReactMultiEmail
            placeholder=''
            emails={account.secondaryEmail ? account.secondaryEmail : []}
            onChange={_emails => {
              setEditingAccount({ ...account, secondaryEmail: _emails })
            }}
            validateEmail={
              email => isEmail(email) // return boolean
            }
            getLabel={(email, index, removeEmail) => (
              <InnerDiv data-tag key={index}>
                {email}
                <span data-tag-handle onClick={() => removeEmail(index)}>
                  x
                </span>
              </InnerDiv>
            )}
          />
        </Div>
      </Row>
      <Row>
        <h4>Venue access</h4>
        <Select
          mode='multiple'
          allowClear
          style={{ width: '100%' }}
          placeholder='Please select'
          value={venues}
          onChange={handleSelectVenuesChange}
        >
          {venueOptions}
        </Select>
      </Row>

      <Row>
        <h4>Credit approved for</h4>
        <Select
          mode='multiple'
          allowClear
          style={{ width: '100%' }}
          placeholder='Please select'
          value={creditApproved}
          onChange={handleSelectCreditChange}
        >
          {creditOptions}
        </Select>
      </Row>

      <Row>
        <h4>Credit limit</h4>
        <Input
          value={creditLimit / 100}
          onChange={event => {
            setEditingAccount({
              ...account,
              creditLimit: event.target.value * 100,
            })
          }}
          addonBefore='£'
          placeholder='0.00'
        />
      </Row>

      <Row>
        <h4>Days credit after statement</h4>
        <Input
          value={creditDays}
          onChange={event => {
            setEditingAccount({ ...account, creditDays: event.target.value })
          }}
          addonAfter='days'
          placeholder='0'
        />
      </Row>
      <Row>
        <SwitchSpan>TFR Admin</SwitchSpan>
        <SwitchComp
          value={TFRAdmin}
          onChange={event => {
            setEditingAccount({ ...account, TFRAdmin: event })
          }}
          checked={TFRAdmin}
        />
      </Row>
      <Row>
        <SwitchSpan>Supplier Admin</SwitchSpan>
        <SwitchComp
          value={supplierAdmin}
          onChange={event => {
            setEditingAccount({ ...account, supplierAdmin: event })
            setShowSupplierDropDown(true)
          }}
          checked={supplierAdmin}
        />
      </Row>
      {showSupplierDropDown && (
        <Select
          mode='multiple'
          style={{ width: '100%' }}
          placeholder='Select Suppliers'
          onChange={handleSupplierChange}
          value={account.supplier}
        >
          {supplierOptions}
        </Select>
      )}
      {supplierAdmin && (
        <Row>
          <SwitchSpan>AI Order Capture</SwitchSpan>
          <SwitchComp
            value={AIOrderCapture}
            onChange={event => {
              setEditingAccount({ ...account, AIOrderCapture: event })
              setShowSupplierDropDown(true)
            }}
            checked={AIOrderCapture}
          />
        </Row>
      )}
      {AIOrderCapture && (
        <Row>
          <h4>AI Order Capture Email</h4>
          <Input
            value={AIOrderCaptureEmail}
            onChange={event => {
              setEditingAccount({
                ...account,
                AIOrderCaptureEmail: event.target.value,
              })
            }}
            placeholder='entter email'
          />
        </Row>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div``

const Row = styled.div`
  margin: 15px 0;
`
const SwitchSpan = styled.span`
  font-weight: bold;
  display: inline-block;
  margin-right: 7px;
`
const Div = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  .react-multi-email {
    border: 1px solid ${({ theme }) => theme.colours.panSilver};
    border-radius: 0;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }
  .react-multi-email:hover,
  .react-multi-email.focused {
    border: 1px solid #b5d922 !important;
  }
`
const Span = styled.span`
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  padding: 8px 10px;
  border: 1px solid ${({ theme }) => theme.colours.panSilver};
  background-color: ${({ theme }) => theme.colours.bgGrey};
`
const InnerDiv = styled.div`
  background-color: ${({ theme }) => theme.colours.lettuceGreen} !important;
`
const SwitchComp = styled(Switch)``

export default EditAccountModal
