import { Modal, Descriptions, Tag } from 'antd'

const ProductModal = ({ visible, onClose, product }) => {
  if (!product) return null

  return (
    <Modal
      visible={visible}
      onCancel={onClose}
      footer={null}
      title={product.name}
    >
      <Descriptions column={1}>
        <Descriptions.Item label='Description'>
          {product.description}
        </Descriptions.Item>
        <Descriptions.Item label='Price'>
          £{product.price.toFixed(2)}
        </Descriptions.Item>
        <Descriptions.Item label='Allergens'>
          {product.allergens.map(allergen => (
            <Tag key={allergen}>{allergen}</Tag>
          ))}
        </Descriptions.Item>
      </Descriptions>

      <h3>Nutritional Information</h3>
      <Descriptions column={2}>
        {Object.entries(product.nutritionalInfo).map(([key, value]) => (
          <Descriptions.Item
            key={key}
            label={key.charAt(0).toUpperCase() + key.slice(1)}
          >
            {value}
            {key === 'calories' ? '' : 'g'}
          </Descriptions.Item>
        ))}
      </Descriptions>
    </Modal>
  )
}

export default ProductModal
