import React, { useState, useEffect } from 'react'
import { Button, Layout } from 'antd'
import { Auth } from 'aws-amplify'
import { useHistory } from 'react-router-dom'
import OMSLogo from 'src/assets/images/OMSLogoWht.png'
import { BodyText, H4 } from 'src/branding/typography'
import styled from 'styled-components/macro'
import { Link as ReactLink } from 'react-router-dom'
import AntMenu from 'antd/lib/menu'
import OrdersIcon from '../../components/icons/OrdersIcon'
import ProductsIcon from '../../components/icons/ProductsIcon'
/*import CustomersIcon from '../../components/icons/CustomersIcon'
import AnalyticsIcon from '../../components/icons/AnalyticsIcon'*/
/*import CustomersIcon from '../../components/icons/CustomersIcon'
import AnalyticsIcon from '../../components/icons/AnalyticsIcon'*/
import FeedbackIcon from '../../components/icons/FeedbackIcon'
import CopilotIcon from '../../components/icons/CopilotIcon'
import SignoutIcon from '../../components/icons/SignoutIcon'
import { useSelector } from 'react-redux'
import { getLogoSupplier } from 'src/features/SupplierdashbaordWrapperSlice'

/*
import DashboardStat from 'src/features/DashboardStat/DashboardStat'
*/

const SupplierdasbaordWrapper = ({ supplierTitle, location, children }) => {
  const history = useHistory()
  const account = useSelector(state => state.auth.account)
  const AIOrderCaptureEmail = useSelector(
    state => state.auth.account.AIOrderCaptureEmail,
  )
  const [selectedKey, setSelectedKey] = useState('orders')
  useEffect(() => {
    const path = location.pathname
    if (path === '/supplier/orders') {
      setSelectedKey('orders')
    } else if (path === '/supplier/products') {
      setSelectedKey('products')
    } else if (path === '/supplier/customers') {
      setSelectedKey('customers')
    } else if (path === '/supplier/feedback') {
      setSelectedKey('feedback')
    } else if (path === '/supplier/analytics') {
      setSelectedKey('analytics')
    } else if (path.includes('/supplier/co-pilot')) {
      setSelectedKey('co-pilot')
    }
  }, [location.pathname])
  const [logoUrl, setLogoUrl] = useState('')

  console.log(location.pathname)

  useEffect(() => {
    const fetchLogo = async () => {
      try {
        const logo = await getLogoSupplier(account?.supplier[0]._id)

        setLogoUrl(logo)
      } catch (error) {
        console.error(error)
      }
    }
    fetchLogo()
  }, [])

  function signOut() {
    return () => Auth.signOut().then(() => history.push('/login'))
  }

  const handleClick = e => {
    setSelectedKey(e.key)
  }
  return (
    <Layout>
      <Header>
        <LeftHeader>
          <Link to='/'>
            <Logo src={OMSLogo} alt='' />
          </Link>
        </LeftHeader>

        <MidHeader>
          <VenueTitle
            style={{
              fontWeight: '900',
              fontSize: '23px',
            }}
          >
            {supplierTitle ? supplierTitle : 'Supplier'}
          </VenueTitle>

          <BodyText></BodyText>
        </MidHeader>

        <StateHeader>
          {/*          <DashboardStat
            StatTitle={'Total orders received'}
            uniqueId={'TotalOrders'}
            isOrdersReceived={true}
          />*/}
        </StateHeader>

        <StateHeader>
          {/*          <DashboardStat
            StatTitle={'Total Revenue on OMS'}
            uniqueId={'TotalRevenue'}
            isOrdersReceived={false}
          />*/}
        </StateHeader>

        <RightHeader>
          <CTAButton
            size='large'
            ghost
            onClick={() =>
              window.open(
                'https://ordersmadesimple.com/get-100-refer-a-friend',
                '_blank',
              )
            }
          >
            {'Refer & Get £100'}
          </CTAButton>
          <CTAButton
            size='large'
            onClick={() => Auth.signOut().then(() => history.push('/login'))}
          >
            Sign out
          </CTAButton>
          <SupplierLogo className='logo-style'>
            <img src={logoUrl} alt='Supplier Logo' />
          </SupplierLogo>
        </RightHeader>
      </Header>
      <Layout>
        <Sider>
          <Menu selectedKeys={[selectedKey]} onClick={handleClick}>
            <Item key='orders'>
              <OrdersIcon size='30px'></OrdersIcon>
              <Link to={`/supplier/orders`}>Orders</Link>
            </Item>
            <Item key='products'>
              <ProductsIcon size='30px'></ProductsIcon>
              <Link to={`/supplier/products`}>Products</Link>
            </Item>
            {/*            <Item key='customers'>
              <CustomersIcon size='30px'></CustomersIcon>
              <Link to={`/supplier/customers`}>Customers</Link>
            </Item>*/}
            {AIOrderCaptureEmail && (
              <Item key='co-pilot'>
                <CopilotIcon size={30} />
                <Link to={`/supplier/co-pilot`}>Co-Pilot</Link>
              </Item>
            )}

            {/*            <Item key='analytics'>
              <AnalyticsIcon size='30px'></AnalyticsIcon>
              <Link to={`/supplier/analytics`}>Analytics</Link>
            </Item>*/}
            <Item key='feedback'>
              <FeedbackIcon size='30px'></FeedbackIcon>
              <Link to={`/supplier/feedback`}>Feedback</Link>
            </Item>
            <Item key='signout'>
              <SignoutIcon size='35px'></SignoutIcon>
              <Link to='/' onClick={signOut()}>
                Sign Out
              </Link>
            </Item>
          </Menu>
        </Sider>
        <Content>{children}</Content>
      </Layout>
    </Layout>
  )
}

const SupplierLogo = styled.div`
  img {
    width: 80px;
    height: 80px;
    border-radius: 15px;
    margin-left: 10px;
  }

  @media (max-width: 1050px) {
    img {
      width: 60px;
      height: 60px;
      border-radius: 15px;
    }
  }
`

const Link = styled(ReactLink)``

const Menu = styled(AntMenu)`
  background: unset !important;
`

const Sider = styled(Layout.Sider)`
  && {
    background: ${({ theme }) => theme.colours.oliveGrey};
  }
`

const Item = styled(Menu.Item)`
  && > a {
    color: ${({ theme }) => theme.colours.mayoWhite};
    text-decoration: underline;
    display: inline;
  }
  &.ant-menu-item-selected > a {
    color: ${({ theme }) => theme.colours.bbqBlack};
  }
  &.ant-menu-item-selected {
    border-radius: 50px;
  }
`

const Header = styled(Layout.Header)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 104px !important;
  background: ${({ theme }) => theme.colours.bbqBlack} !important;
  padding: 0 ${({ theme }) => theme.spacing.xxl} !important;
  @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    height: 150px !important;
    flex-wrap: wrap;
    padding: 0 ${({ theme }) => theme.spacing.md} !important;
  }
  @media (max-width: 1600px) {
    height: 150px !important;
    flex-wrap: wrap;
    padding: 0 ${({ theme }) => theme.spacing.xxs} !important;
  }
  @media (max-width: 1300px) {
    height: 220px !important;
    flex-wrap: wrap;
    padding: 0 ${({ theme }) => theme.spacing.xxs} !important;
  }
  @media (max-width: 935px) {
    height: 340px !important;
    flex-wrap: wrap;
    padding: 0 ${({ theme }) => theme.spacing.xxs} !important;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    height: 340px !important;
    flex-wrap: wrap;
    padding: 0 ${({ theme }) => theme.spacing.xxs} !important;
  }
`

const Logo = styled.img`
  max-height: 100%;
  height: 80px;
  margin-top: 4px;
`

const Content = styled(Layout.Content)`
  min-height: calc(100vh - 100px) !important;
  @media (max-width: ${({ theme }) => theme.breakpoints.xl}px) {
    min-height: calc(100vh - 150px) !important;
  }
`

const StateHeader = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  max-width: 1200px;
  padding: 0 10px;
  margin: 0 auto;
  white-space: nowrap;
`

const LeftHeader = styled.div`
  display: flex;
  /* flex-direction: row; */
  justify-content: flex-start;
  align-items: center;
  width: 82px;
  /* background: beige; */
`

const RightHeader = styled.div`
  display: flex;
  /* flex-direction: row; */
  justify-content: flex-end;
  align-items: center;
  max-width: 260px;
  float: right;
  @media (max-width: ${({ theme }) => theme.breakpoints.xl}px) {
    width: 100%;
    max-width: 100%;
    margin-bottom: 6px;
  }
`

const MidHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 268px;
  margin: 0 auto;
  line-height: 1;
  padding-right: 16px;

  @media (max-width: ${({ theme }) => theme.breakpoints.xl}px) {
    // width: 75%;
    max-width: 100%;
    justify-content: center;
    padding-right: 16px;
  }

  @media (max-width: 1480px) and (min-width: 1291px) {
    margin-left: auto;
    margin-right: auto;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    align-items: flex-end;
  }
`

const VenueTitle = styled(H4)`
  text-align: left;
  color: ${({ theme }) => theme.colours.lettuceGreen};
  width: 100%;
  @media (min-width: 1290px) and (max-width: 1480px) {
    // position: absolute;
    // right: 220px;
  }
`

const CTAButton = styled(Button)`
  height: 48px !important;
  margin-left: ${({ theme }) => theme.spacing.sm};
`

export default SupplierdasbaordWrapper
