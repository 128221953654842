import styled from 'styled-components'
import { Table } from 'antd'
import { CheckOutlined } from '@ant-design/icons'

const StyledTable = styled(Table)`
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;

  .ant-table {
    background: #fff;
    border-radius: 8px;
  }

  .ant-table-thead > tr > th {
    text-align: center;
    padding: 10px;
    background-color: #f5f5f5;
    font-weight: bold;
    border-bottom: 2px solid #ddd;
  }

  .ant-table-tbody > tr > td {
    text-align: center;
    padding: 10px;
    border-bottom: 1px solid #ddd;
  }

  .ant-table-tbody > tr:nth-child(even) {
    background-color: #fafafa;
  }

  .ant-table-tbody > tr:hover {
    background-color: #e6f7ff;
    transition: background 0.3s ease-in-out;
  }
`

const allergens = [
  'Celery',
  'Gluten',
  'Crustaceans',
  'Eggs',
  'Fish',
  'Lupin',
  'Milk',
  'Molluscs',
  'Mustard',
  'Nuts',
  'Peanuts',
  'Sesame seeds',
  'Soya',
  'Sulphites',
]

const AllergenGrid = ({ dishes, businessName }) => {
  const columns = [
    {
      title: 'Dishes',
      dataIndex: 'name',
      key: 'name',
      fixed: 'left',
      width: 200,
    },
    ...allergens.map(allergen => ({
      title: allergen,
      dataIndex: allergen.toLowerCase().replace(' ', '_'),
      key: allergen.toLowerCase().replace(' ', '_'),
      align: 'center',
      render: value =>
        value ? <CheckOutlined style={{ color: '#52c41a' }} /> : null,
    })),
  ]

  const data = dishes.map(dish => ({
    key: dish.name,
    name: dish.name,
    ...allergens.reduce(
      (acc, allergen) => ({
        ...acc,
        [allergen.toLowerCase().replace(' ', '_')]: dish.allergens.includes(
          allergen.toLowerCase(),
        ),
      }),
      {},
    ),
  }))

  return (
    <div>
      <h2 style={{ textAlign: 'center', marginBottom: '16px' }}>
        {businessName} - Allergen Information
      </h2>
      <StyledTable
        columns={columns}
        dataSource={data}
        pagination={false}
        scroll={{ x: 'max-content' }}
      />
      <p
        style={{
          textAlign: 'center',
          marginTop: '12px',
          fontSize: '14px',
          color: '#666',
        }}
      >
        Produced by Orders Made Simple on {new Date().toLocaleDateString()}
      </p>
    </div>
  )
}

export default AllergenGrid
