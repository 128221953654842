import { createSlice } from '@reduxjs/toolkit'
import { SuccessNotification } from 'src/notifications/SuccessNotification'
import { API } from 'aws-amplify'

const initialState = {
  recipes: [],
  categories: [],
  isLoading: false,
  error: null,
  success: false,
}

const recipeBuilderSlice = createSlice({
  name: 'recipes',
  initialState,
  reducers: {
    setIsLoading: (state, action) => {
      state.isLoading = action.payload
    },
    setRecipes: (state, action) => {
      state.recipes = action.payload
    },
    setCategories: (state, action) => {
      state.categories = action.payload
    },
    setError: (state, action) => {
      state.error = action.payload
    },
    setSuccess: (state, action) => {
      state.success = action.payload
    },
  },
})

export const fetchAllRecipes = venueId => async dispatch => {
  dispatch(setIsLoading(true))
  try {
    const data = await API.get('api2', `recipes/${venueId}`)
    dispatch(setRecipes(data?.recipes))
    dispatch(setIsLoading(false))
  } catch (error) {
    dispatch(setError(error))
    dispatch(setIsLoading(false))
  }
}

export const fetchCategories = () => async dispatch => {
  try {
    const data = await API.get('api2', 'recipes/categories/fetchCategories')
    dispatch(setCategories(data.categories))
  } catch (error) {
    dispatch(setError(error))
  }
}

export const deleteRecipe = (recipedId, venueId) => async dispatch => {
  dispatch(setIsLoading(true))
  try {
    const result = await API.del('api2', `recipes/${recipedId}`)
    SuccessNotification({ message: result?.message })
    await dispatch(fetchAllRecipes(venueId))
  } catch (error) {
    dispatch(setError(error))
  }
}

export const createRecipe = (venueId, formData) => async dispatch => {
  dispatch(setIsLoading(true))
  try {
    const data = { ...formData, venue: venueId }
    const response = await API.post('api2', 'recipes/createRecipe', {
      body: data,
    })
    SuccessNotification({ message: response?.message })
    dispatch(setIsLoading(false))
    dispatch(setSuccess(true))
    await dispatch(fetchAllRecipes(venueId))
  } catch (error) {
    dispatch(setIsLoading(false))
    dispatch(setError(error))
  }
}

export const editRecipe =
  (recipeId, updatedData, venueId) => async dispatch => {
    dispatch(setIsLoading(true))
    try {
      const data = await API.put('api2', `recipes/${recipeId}`, {
        body: updatedData,
      })

      SuccessNotification({ message: data?.message })
      dispatch(fetchAllRecipes(venueId))
    } catch (error) {
      dispatch(setError(error))
    } finally {
      dispatch(setIsLoading(false))
    }
  }

export const { setIsLoading, setRecipes, setError, setCategories, setSuccess } =
  recipeBuilderSlice.actions

export default recipeBuilderSlice
