import React from 'react'
import styled from 'styled-components'
import HeaderScreen from 'src/components/shared/header-screen/HeaderScreen'
import { useDispatch, useSelector } from 'react-redux'
import dayjs from 'dayjs'
import { toggleSubscription } from 'src/features/VenueSlice'

const Subscriptions = () => {
  const venueId = useSelector(state => state.venue?.venue?._id)
  const subscriptions = useSelector(state => state.venue?.venue?.subscriptions)
  const freeTrial = useSelector(state => state.venue?.venue?.freeTrial)
  const dispatch = useDispatch()
  const account = useSelector(state => state.auth.account)

  const toggleSubscriptionData = data => {
    data.isAdmin = account.isAdmin || account.isTFRAdmin
    dispatch(toggleSubscription(data))
  }
  const subscriptionsTitles = [
    { title: 'OMS Plus', key: 'omsplus' },
    { title: 'Full Invoice Reconciliation', key: 'reconcilaition' },
    { title: 'Credit Note Automation', key: 'creditNotesAutomation' },
    { title: 'Price Tracking', key: 'pricetracking' },
    { title: 'Invoice Storage', key: 'invoiceStoarge' },
    { title: 'Add Suppliers', key: 'addSuppliers' },
  ]

  const calculateRemainingDays = endDate => {
    const now = dayjs()
    const end = dayjs(endDate)
    return end.diff(now, 'day')
  }

  return (
    <Wrapper>
      <HeaderScreen title='Your Active Subscriptions' />

      {/* Free Trial Section */}
      <Section>
        <SectionHeader>
          <h2>Free Trial</h2>
        </SectionHeader>

        <Card>
          <p>
            <strong>One Month Trial:</strong>{' '}
            {freeTrial?.oneMonthTrial ? 'Active' : 'Inactive'}
          </p>
          {freeTrial?.oneMonthTrialStarted && (
            <p>
              <strong>Started:</strong>{' '}
              {dayjs(freeTrial?.oneMonthTrialStarted).format('MMMM D, YYYY')}
            </p>
          )}
          {freeTrial?.oneMonthTrialEnded && (
            <p>
              <strong>Ended:</strong>{' '}
              {dayjs(freeTrial?.oneMonthTrialEnded).format('MMMM D, YYYY')}
            </p>
          )}

          {freeTrial && freeTrial?.oneMonthTrial && (
            <p>
              <strong>Remaining Days:</strong>{' '}
              {calculateRemainingDays(freeTrial?.oneMonthTrialEnded)} days
            </p>
          )}

          <p>
            <strong>Two Weeks Trial:</strong>{' '}
            {freeTrial?.twoWeeeksTrial ? 'Active' : 'Inactive'}
          </p>
          {freeTrial?.twoWeeeksTrialStarted && (
            <p>
              <strong>Started:</strong>{' '}
              {dayjs(freeTrial?.twoWeeeksTrialStarted).format('MMMM D, YYYY')}
            </p>
          )}
          {freeTrial?.twoWeeeksTrialStarted && (
            <p>
              <strong>Ends On:</strong>{' '}
              {dayjs(freeTrial?.twoWeeeksTrialStarted)
                .add(14, 'day')
                .format('MMMM D, YYYY')}
            </p>
          )}

          {freeTrial && freeTrial?.twoWeeeksTrial && (
            <p>
              <strong>Remaining Days:</strong>{' '}
              {calculateRemainingDays(
                dayjs(freeTrial?.twoWeeeksTrialStarted).add(14, 'day'),
              )}{' '}
              days
            </p>
          )}
        </Card>
      </Section>
      <Section>
        <SectionHeader>
          <h2>Subscriptions</h2>
        </SectionHeader>

        {subscriptionsTitles.map(
          ({ title, key }) =>
            subscriptions[key] && (
              <Card key={key}>
                <SubscriptionTitle>
                  <strong>{title}:</strong>
                </SubscriptionTitle>
                <p>
                  <strong>Subscribed:</strong>{' '}
                  {subscriptions[key].isSubscribed ? 'Yes' : 'No'}
                </p>
                <p>
                  <strong>Subscription ID:</strong>{' '}
                  {subscriptions[key].subscriptionId || 'N/A'}
                </p>
                <p>
                  <strong>Charge ID:</strong>{' '}
                  {subscriptions[key].chargeId || 'N/A'}
                </p>

                <ToggleButton
                  onClick={() =>
                    toggleSubscriptionData({
                      venueId,
                      title,
                      isSubscribed: subscriptions[key].isSubscribed,
                      isAdmin:
                        account && (account.isAdmin || account.isTFRAdmin),
                    })
                  }
                  isSubscribed={subscriptions[key].isSubscribed}
                >
                  {subscriptions[key].isSubscribed
                    ? 'Cancel Subscription'
                    : 'Subscribe'}
                </ToggleButton>
              </Card>
            ),
        )}
      </Section>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  padding: 20px;
  background-color: #f9f9f9;
  min-height: 100vh;
`

const Section = styled.div`
  margin-bottom: 20px;
`

const SectionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`

const Card = styled.div`
  background-color: white;
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
`

const SubscriptionTitle = styled.p``

const ToggleButton = styled.button`
  background-color: ${({ isSubscribed }) =>
    isSubscribed ? '#ff4d4d' : '#b5d922'};
  color: white;
  border: none;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;

  &:hover {
    background-color: ${({ isSubscribed }) =>
      isSubscribed ? '#cc0000' : '#218838'};
  }
`

export default Subscriptions
