import { io } from 'socket.io-client'
import config from 'src/config'

let socket
let url = config.apiGateway.EXPRESS_URL
let newurl = url.replace('/api/v1/', '')
const useSocket = userId => {
  if (!socket && userId) {
    socket = io(newurl, {
      query: { userId },
      transports: ['websocket'],
    })
  }

  return socket
}

export default useSocket
