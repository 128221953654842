import { VAT_RATE } from 'src/config'

const calculatePrice = (price, hasVat) => {
  const productPriceIncExVAT = hasVat ? price * 100 * VAT_RATE : price * 100
  const val = Math.round(productPriceIncExVAT)
  return (val / 100).toFixed(2)
}

export const getPrice = item => {
  const specialProdprice = item?.isSpecialOrder ? item?.specialProductPrice : ''
  const price =
    item.price !== undefined && !isNaN(item.price)
      ? item.price
      : item.product.price !== undefined && !isNaN(item.product.price)
      ? item.product.price
      : 0
  const hasVat = item.hasVat !== undefined ? item.hasVat : item.product.hasVat
  return calculatePrice(specialProdprice ? specialProdprice : price, hasVat)
}

export const getOMSPrice = item => {
  const specialProdprice = item?.isSpecialOrder ? item?.OMSSpecialPrice : ''
  const price =
    item.OMSPrice !== undefined && !isNaN(item.OMSPrice)
      ? item.OMSPrice
      : item.product.OMSPrice !== undefined
      ? item.product.OMSPrice
      : 0

  let adminReceiptingPrice
  if (
    item?.receipting &&
    item.receipting?.error === 'Adjustment due to Invoice received' &&
    item?.receipting?.updatedPrice >= 0
  ) {
    adminReceiptingPrice = item.receipting?.updatedPrice / 100
  } else {
    adminReceiptingPrice = price
  }
  return calculatePrice(
    adminReceiptingPrice !== price
      ? adminReceiptingPrice
      : specialProdprice
      ? specialProdprice
      : adminReceiptingPrice,
    false,
  )
}

const calculatePriceWithQuantity = (
  price,
  hasVat,
  quantity,
  updatedQuantity,
) => {
  const productPriceIncExVAT = hasVat ? price * 100 * VAT_RATE : price * 100
  let val = productPriceIncExVAT * (updatedQuantity || quantity)
  val = Math.round(val)
  return (val / 100).toFixed(2)
}

export const getOrderPrice = item => {
  // if it is undefined  it will be broke
  // TODO: prevent the undefined price error
  const price =
    item.price !== undefined && !isNaN(item.price)
      ? item.price
      : item.product.price !== undefined
      ? item.product.price
      : 0

  const specialProdprice = item?.isSpecialOrder ? item?.specialProductPrice : ''
  // item.price !== undefined
  //   ? item.price
  //   : item.product.price !== undefined
  //   ? item.product.price
  //   : 0

  const hasVat = item.hasVat !== undefined ? item.hasVat : item.product.hasVat
  const { updatedQuantity, quantity } = item

  return calculatePriceWithQuantity(
    specialProdprice ? specialProdprice : price,
    hasVat,
    quantity,
    updatedQuantity,
  )
}

// For orders already commpleted
export const getOrderPriceNoVAT = item => {
  let price = 0

  if (isNaN(item.price)) {
    price = 0
  } else {
    price = item.price === undefined ? 0 : item.price
  }

  const { updatedQuantity, quantity } = item

  return calculatePriceWithQuantity(price, false, quantity, updatedQuantity)
}

export const getTotalPrice = array =>
  array
    ? array.reduce(
        (accumulator, item) =>
          Number(
            parseFloat(accumulator) + parseFloat(getOrderPrice(item)),
          ).toFixed(2),
        0,
      )
    : 0

export const getTotalPriceNoVAT = array =>
  array
    ? array.reduce(
        (accumulator, item) =>
          Number(
            parseFloat(accumulator) + parseFloat(getOrderPriceNoVAT(item)),
          ).toFixed(2),
        0,
      )
    : 0

export const getTotalOMSPrice = array =>
  array
    ? array.reduce(
        (accumulator, item) => {
          const specialProdprice = item?.isSpecialOrder
            ? item?.OMSSpecialPrice
            : ''
          const price =
            item.OMSPrice !== undefined && !isNaN(item.OMSPrice)
              ? item.OMSPrice
              : item.product.OMSPrice !== undefined
              ? item.product.OMSPrice
              : 0

          // const adminReceiptingPrice =
          //     (item?.receipting?.error === 'Adjustment due to Invoice received' &&
          //         item?.receipting?.updatedPrice / 100) ||
          //     specialProdprice ||
          //     price;

          let adminReceiptingPrice

          if (
            item?.receipting?.error === 'Adjustment due to Invoice received' &&
            item?.receipting?.updatedPrice >= 0
          ) {
            adminReceiptingPrice = item?.receipting?.updatedPrice / 100
          } else {
            adminReceiptingPrice = specialProdprice || price
          }

          const hasVat =
            item.hasVat !== undefined ? item.hasVat : item.product.hasVat
          const { updatedQuantity, quantity, supplierConfirmed, receipting } =
            item

          if (!supplierConfirmed) {
            return accumulator
          }

          // const adminReceiptingQuantity =
          //     (receipting?.error === 'Adjustment due to Invoice received' &&
          //         (item.UoM === 'Kg' && receipting?.weight
          //             ? receipting.weight
          //             : receipting?.updatedQuantity)) ||
          //     updatedQuantity ||
          //     quantity;

          let adminReceiptingQuantity

          if (receipting?.error === 'Adjustment due to Invoice received') {
            if (item.UoM === 'Kg' && receipting?.weight >= 0) {
              adminReceiptingQuantity = receipting?.weight
            } else if (receipting?.updatedQuantity >= 0) {
              adminReceiptingQuantity = receipting?.updatedQuantity
            } else {
              adminReceiptingQuantity = updatedQuantity || quantity
            }
          } else {
            adminReceiptingQuantity = updatedQuantity || quantity
          }

          const total = parseFloat(
            adminReceiptingQuantity * adminReceiptingPrice,
          )
          accumulator.total += total

          if (hasVat) {
            const vat = parseFloat(total * 0.2)
            accumulator.VAT += vat
            accumulator.totalVat += total + vat
          } else {
            accumulator.totalVat += total
          }

          return accumulator
        },
        { total: 0, VAT: 0, totalVat: 0 },
      )
    : { total: 0, VAT: 0, totalVat: 0 }

export const getTotalSpendReceipting = array => {
  const filterdOrders = array.filter(
    order =>
      order.supplierConfirmed === undefined || order.supplierConfirmed === true,
  )
  const totalPrice = filterdOrders?.reduce((total, item) => {
    let quantity = !isNaN(item.updatedQuantity)
      ? item.updatedQuantity
      : !isNaN(item?.receipting?.updatedQuantity) &&
        item?.receipting?.error === 'Supplier / Invoice adjustment'
      ? item?.receipting?.updatedQuantity
      : !isNaN(item?.receipting?.previousQty)
      ? item?.receipting?.previousQty
      : item?.quantity
    const sum =
      item.receiptingStatus === 'Rejected by supplier'
        ? item.product?.price * item.quantity
        : (item.receipting?.updatedPrice >= 0
            ? item.receipting?.updatedPrice
            : item?.price || item?.product?.price) * quantity

    return total + sum
  }, 0)
  return totalPrice
}

// // Utility function to calculate total price
// export function calculateTotalPrice(price, size, uom) {
//   // Ensure size is a positive number
//   if (typeof size !== 'number' || size <= 0) {
//       throw new Error("Size must be a positive number");
//   }

//   // Calculate total price based on UoM
//   switch (uom.toLowerCase()) {
//       case 'kg':
//       case 'each':
//       case 'pack':
//       case 'box':
//           // For these units, the total price is simply price * size
//           return price * size;
//       default:
//           throw new Error("Unsupported unit of measure (UoM)");
//   }
// }

// Utility function to calculate total price based on size change
export const calculateTotalPrice = (originalSize, originalPrice, newSize) => {
  const { numbers, uom, totalSize } = extractAndCalculateSize(originalSize)

  console.log('{ numbers, uom, totalSize }  -----> ', {
    numbers,
    uom,
    totalSize,
    originalSize,
  })
  // Ensure all inputs are valid
  if (typeof Number(numbers[0]) !== 'number' || Number(numbers[0]) <= 0) {
    console.log('Original size must be a positive number')
  }
  if (typeof Number(originalPrice) !== 'number' || Number(originalPrice) <= 0) {
    console.log('Original price must be a positive number')
  }
  if (typeof Number(newSize) !== 'number' || Number(newSize) <= 0) {
    console.log('New size must be a positive number')
  }
  // Calculate the total price based on the new size
  const totalPrice = (originalPrice / numbers[0]) * newSize
  return totalPrice
}

export const extractAndCalculateSize = input => {
  // Check if input is a pure number
  if (!isNaN(input.trim())) {
    return { numbers: [Number(input)], uom: null, totalSize: Number(input) }
  }

  // Extract numbers
  const numbers = input.match(/\d+/g)?.map(Number) || []

  // Extract UOM (everything except numbers and separators)
  const uom = input.replace(/[\d\sxX]/g, '').trim() || null

  // Calculate total size
  const totalSize =
    numbers.length > 0 ? numbers.reduce((acc, num) => acc * num, 1) : 0

  return { numbers, uom, totalSize }
}

// Test cases:
// const inputs = ["1 x 10kg", "12", "5", "2 x 3 x 4m", "15L"];

// inputs.forEach(str => {
//   console.log(`${str} =>`, extractAndCalculateSize(str));
// });
// 1 x 10kg => { numbers: [1, 10], uom: 'kg', totalSize: 10 }
// 12 => { numbers: [12], uom: null, totalSize: 12 }
// 5 => { numbers: [5], uom: null, totalSize: 5 }
// 2 x 3 x 4m => { numbers: [2, 3, 4], uom: 'm', totalSize: 24 }
// 15L => { numbers: [15], uom: 'L', totalSize: 15 }
