import { createSlice } from '@reduxjs/toolkit'
import { API, Auth } from 'aws-amplify'
import { editProduct } from 'src/features/ProductSlice'
import Sentry from 'src/utils/sentry'

const initialState = {
  term: '',
  products: [],
  fields: ['title', 'sku', 'supplierTitle'],
  page: 1,
  pageSize: 200,
  total: 0,
  loading: false,
  error: '',
}

const catalogueSearchSlice = createSlice({
  name: 'catalogueSearch',
  initialState,

  reducers: {
    setTerm: (state, action) => {
      const term = action.payload
      state.term = term
      if (!term.trim()) {
        state.products = []
      }
    },
    setProducts: (state, action) => {
      state.products = action.payload
    },
    setFields: (state, action) => {
      state.fields = action.payload
    },
    setPage: (state, action) => {
      state.page = action.payload
    },
    setPageSize: (state, action) => {
      state.pageSize = action.payload
    },
    setTotal: (state, action) => {
      state.total = action.payload
    },
    setLoading: (state, action) => {
      state.loading = action.payload
    },
    setError: (state, action) => {
      state.error = action.payload
    },
    editSearchProduct: (state, action) => {
      const index = state.products.findIndex(
        product => product._id === action.payload._id,
      )
      if (index !== -1) state.products[index] = action.payload
    },
    resetCatalogueSearch: state => {
      state = initialState
      return state
    },
  },
})

export const {
  setTerm,
  setProducts,
  setFields,
  setPage,
  setPageSize,
  setTotal,
  setLoading,
  setError,
  editSearchProduct,
  setCatalougeSearchFromSuppliers,
  clearCatalougeFilter,
  resetCatalogueSearch,
} = catalogueSearchSlice.actions

export const performSearch =
  ({ venueId, pageNumber, pageSize }) =>
  async (dispatch, getState) => {
    const searchTerm = getState().catalogueSearch.term.trimEnd()
    const searchFields = getState().catalogueSearch.fields

    dispatch(setLoading(true))

    const searchPath = venueId ? `venue/${venueId}/catalogue` : `products`

    try {
      const session = await Auth.currentAuthenticatedUser()
      const searchResults = await API.post('api', searchPath, {
        body: {
          searchTerm,
          searchFields,
          searchPage: pageNumber,
          searchPerPage: pageSize,
          filterValues: [],
        },
        queryStringParameters: {
          username: session.attributes.sub,
        },
      })
      dispatch(setTotal(searchResults.total))
      dispatch(setProducts(searchResults.results))
      dispatch(setPageSize(searchResults.pageSize))
      dispatch(setPage(searchResults.pageNumber))
      dispatch(setLoading(false))
    } catch (e) {
      console.error('Got error response from search endpoint:', e)
      Sentry.captureException(e)
      dispatch(setLoading(false))
      dispatch(
        setError('Something went wrong when searching. Please try again.'),
      )
    }
  }

export const adminEditProductFromSearch = product => async dispatch => {
  try {
    const _product = await API.post('api', `product`, {
      body: product,
    })
    dispatch(editSearchProduct(_product))
    dispatch(editProduct(_product))
  } catch (e) {
    console.error(e)
    Sentry.captureException(e)
    return false
  }
}

export default catalogueSearchSlice
