import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import {
  UploadOutlined,
  DeleteOutlined,
  CloseCircleOutlined,
  LoadingOutlined,
} from '@ant-design/icons'
import { uploadFile, deleteFile } from 'src/utils/uploadFile'

const RecipeNotes = ({
  handleChange,
  uploadingIndexes,
  setUploadingIndexes,
}) => {
  const [notes, setNotes] = useState({ text: '', images: [] })
  const [localImageUrls, setLocalImageUrls] = useState([])
  const [selectedFiles, setSelectedFiles] = useState([])

  const handleImageUpload = event => {
    event.preventDefault()
    const files = Array.from(event.target.files)
    const newLocalUrls = files.map(file => URL.createObjectURL(file))

    setLocalImageUrls(prev => [...prev, ...newLocalUrls])
    setSelectedFiles(prev => [...prev, ...files])
  }

  const handleUploadClick = async () => {
    if (selectedFiles.length === 0) return

    setUploadingIndexes(new Array(selectedFiles.length).fill(true))

    const uploadedImages = await Promise.all(
      selectedFiles.map(async (file, index) => {
        try {
          const uploadedData = await uploadFile(file)
          return uploadedData || null
        } catch (error) {
          console.error('Error uploading file:', error)
          return null
        } finally {
          setUploadingIndexes(prev => {
            const updatedIndexes = [...prev]
            updatedIndexes[index] = false
            return updatedIndexes
          })
        }
      }),
    )

    const filteredImages = uploadedImages.filter(img => img !== null)

    setNotes(prevNotes => ({
      ...prevNotes,
      images: [...prevNotes.images, ...filteredImages],
    }))

    setLocalImageUrls([])
    setSelectedFiles([])
  }

  // Delete all notes and images
  const deleteNote = () => {
    setNotes({ text: '', images: [] })
    setLocalImageUrls([])
    setSelectedFiles([])
    setUploadingIndexes([])
  }

  // Delete a specific image
  const deleteImage = async index => {
    if (index < notes.images.length) {
      const imageToDelete = notes.images[index]
      if (imageToDelete?.keyName) {
        const success = await deleteFile(imageToDelete.keyName)
        if (!success) return
      }

      setNotes(prevNotes => ({
        ...prevNotes,
        images: prevNotes.images.filter((_, i) => i !== index),
      }))
    } else {
      setLocalImageUrls(prev =>
        prev.filter((_, i) => i !== index - notes.images.length),
      )
      setSelectedFiles(prev =>
        prev.filter((_, i) => i !== index - notes.images.length),
      )
    }
  }

  useEffect(() => {
    handleChange('notes', notes)
  }, [notes])

  return (
    <Container>
      <Title>Recipe Notes</Title>

      {/* Note Input */}
      <NoteContainer>
        <Label>Note</Label>
        {notes.text && (
          <DeleteButton onClick={deleteNote}>
            <DeleteOutlined style={{ color: 'red' }} />
          </DeleteButton>
        )}
        <NoteInput
          placeholder='Write your note...'
          value={notes.text}
          onChange={e =>
            setNotes(prevNotes => ({
              ...prevNotes,
              text: e.target.value,
            }))
          }
        />
      </NoteContainer>

      <ImageUploadBox>
        <UploadIcon>
          <UploadOutlined />
        </UploadIcon>
        <UploadText>Select images to upload</UploadText>
        <HiddenFileInput
          style={{ display: 'none' }}
          type='file'
          accept='image/*'
          multiple
          onChange={handleImageUpload}
        />
      </ImageUploadBox>

      <UploadButton
        disabled={selectedFiles.length === 0}
        onClick={handleUploadClick}
      >
        Upload Images
      </UploadButton>

      {(localImageUrls.length > 0 || notes.images.length > 0) && (
        <ImageGrid>
          {localImageUrls.map((url, index) => (
            <ImageWrapper key={index}>
              <UploadedImage src={url} alt={`uploaded-${index}`} />
              {uploadingIndexes[index] && (
                <LoaderOverlay>
                  <LoadingOutlined style={{ fontSize: 24, color: '#fff' }} />
                </LoaderOverlay>
              )}
              <DeleteImageButton
                onClick={() => deleteImage(index + notes.images.length)}
              >
                <CloseCircleOutlined />
              </DeleteImageButton>
            </ImageWrapper>
          ))}

          {notes.images.map((img, index) => (
            <ImageWrapper key={index}>
              <UploadedImage src={img.signedURL} alt={`uploaded-${index}`} />
              <DeleteImageButton onClick={() => deleteImage(index)}>
                <CloseCircleOutlined />
              </DeleteImageButton>
            </ImageWrapper>
          ))}
        </ImageGrid>
      )}
    </Container>
  )
}

const Container = styled.div`
  padding: 20px;
  background-color: #fff;
  margin: auto;
  border-radius: 10px;
  margin: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
`

const Title = styled.h2`
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
`

const NoteContainer = styled.div`
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 10px;
  position: relative;
`

const Label = styled.label`
  font-size: 16px;
  font-weight: 500;
  display: block;
  margin-bottom: 5px;
`

const DeleteButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  cursor: pointer;
`

const NoteInput = styled.textarea`
  width: 100%;
  min-height: 80px;
  font-size: 16px;
  border: none;
  outline: none;
  resize: none;
`

const ImageUploadBox = styled.label`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px dashed #bbb;
  border-radius: 8px;
  padding: 15px;
  margin-top: 15px;
  cursor: pointer;
`

const UploadIcon = styled.div`
  margin-right: 10px;
  font-size: 20px;
`

const UploadText = styled.span`
  font-size: 14px;
  color: #555;
`

const HiddenFileInput = styled.input`
  display: none;
`

const UploadButton = styled.button`
  background-color: ${props => (props.disabled ? '#ccc' : '#B4D820')};
  color: white;
  border: none;
  padding: 10px;
  margin-top: 10px;
  border-radius: 5px;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
`

const ImageGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
`

const ImageWrapper = styled.div`
  position: relative;
  margin-right: 10px;
  margin-bottom: 10px;
`

const UploadedImage = styled.img`
  width: 80px;
  height: 80px;
  border-radius: 8px;
  object-fit: cover;
`

const LoaderOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 8px;
`

const DeleteImageButton = styled.button`
  position: absolute;
  top: 5px;
  right: 5px;
  background: rgba(0, 0, 0, 0.6);
  color: white;
  border: none;
  border-radius: 50%;
  padding: 5px;
  cursor: pointer;
`

export default RecipeNotes
