import React from 'react'
import { Card, Row, Col, Statistic } from 'antd'
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  ClockCircleOutlined,
  FileTextOutlined,
} from '@ant-design/icons'
import styled from 'styled-components'

const TrackingOverview = ({ trackingOverview }) => {
  if (!trackingOverview) return null

  return (
    <StyledCard title='Tracking Overview' bordered={false}>
      <Row gutter={[16, 16]}>
        <Col span={6}>
          <Statistic
            title='Total Orders'
            value={trackingOverview.totalOrders}
            prefix={<FileTextOutlined style={{ color: '#1890ff' }} />}
          />
        </Col>
        <Col span={6}>
          <Statistic
            title='Approved Orders'
            value={trackingOverview.approvedOrders}
            valueStyle={{ color: '#52c41a' }}
            prefix={<CheckCircleOutlined />}
          />
        </Col>
        <Col span={6}>
          <Statistic
            title='Rejected Orders'
            value={trackingOverview.rejectedOrders}
            valueStyle={{ color: '#ff4d4f' }}
            prefix={<CloseCircleOutlined />}
          />
        </Col>
        <Col span={6}>
          <Statistic
            title='Unprocessed Orders'
            value={trackingOverview.unprocessedOrders}
            valueStyle={{ color: '#faad14' }}
            prefix={<ClockCircleOutlined />}
          />
        </Col>
      </Row>
    </StyledCard>
  )
}

const StyledCard = styled(Card)`
  border-radius: 10px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  .ant-statistic-title {
    font-size: 14px;
    color: #555;
  }
  .ant-statistic-content {
    font-size: 18px;
    font-weight: bold;
  }
`

export default TrackingOverview
