import React from 'react'
import { Modal } from 'antd'
import styled from 'styled-components'

const OriginalOrderModal = ({
  visibleOriginalOrderModal,
  setVisibleOriginalOrderModal,
  email,
}) => {
  const getFileExtension = url => {
    if (!url) return null
    const parts = url.split('.')
    return parts.length > 1 ? parts.pop().toLowerCase() : null
  }
  const fileExtension = getFileExtension(email?.fileLink)

  return (
    <Modal
      width={'60%'}
      title={'Original Order'}
      visible={visibleOriginalOrderModal}
      onCancel={() => setVisibleOriginalOrderModal(false)}
      footer={null}
    >
      <Card>
        <EmailBody dangerouslySetInnerHTML={{ __html: email?.body }} />
        {email?.fileLink && (
          <a
            style={{
              marginTop: 20,
              display: 'block',
            }}
            href={email?.fileLink}
            target='_blank'
            rel='noopener noreferrer'
          >
            Open File
          </a>
        )}

        {email?.transcription && fileExtension.toLowerCase() != 'html' && (
          <div
            style={{
              marginTop: 20,
              borderTop: '1px solid #ddd',
              paddingTop: 20,
            }}
          >
            <h4>Transcription</h4>
            <EmailBody>{email?.transcription}</EmailBody>
          </div>
        )}
      </Card>
    </Modal>
  )
}

const EmailBody = styled.div`
  color: #555;
  margin-top: 10px;
  font-size: 14px;
`

const Card = styled.div`
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 20px;
  background: #ffffff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`

export default OriginalOrderModal
