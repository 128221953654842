import React from 'react'
import { useSelector } from 'react-redux'
import { Redirect, Route, Switch, useLocation } from 'react-router-dom'
import Accounts from 'src/screens/accounts/Accounts'
import AdminRoute from 'src/screens/app/AdminRoute'
import DashboardWrapper from 'src/screens/app/DashboardWrapper'
import LoggedInRoute from 'src/screens/app/LoggedInRoute'
import VenueInternalWrapper from 'src/screens/app/VenueInternalWrapper'
import Catalogue from 'src/screens/catalogue/Catalogue'
import Invoice from 'src/screens/invoice/Invoice'
import Login from 'src/screens/login/Login'
import OrderChecklist from 'src/screens/order-checklist/OrderChecklist'
import Basket from 'src/screens/orders/basket/Basket'
import Credit from 'src/screens/orders/Credit'
import Invoices from 'src/screens/orders/Invoices'
import OrderedProducts from 'src/screens/orders/OrderedProducts'
import Orders from 'src/screens/orders/Orders'
import SpendOverTime from 'src/screens/orders/spend-over-time/SpendOverTime'
import Products from 'src/screens/products/Products'
import Register from 'src/screens/register/Register'
import ResetPassword from 'src/screens/reset-password/ResetPassword'
import Settings from 'src/screens/settings/Settings'
import Suppliers from 'src/screens/suppliers/Suppliers'
import VenueProducts from 'src/screens/venue-products/VenueProducts'
import VenueSuppliers from 'src/screens/venue-suppliers/VenueSuppliers'
import Venues from 'src/screens/venues/Venues'
import AdminOrders from '../admin-order/AdminOrders'
import PastOrders from '../overview/PastOrders'
import PastOrdersSpending from '../past-orders-spending/PastOrdersSpending'
import Spending from '../spending/Spending'
import OverViewRoute from './OverviewRoute'
import Statement from '../orders/Statement'
import StockList from '../stock/StockList'
import Stock from '../stock/Stock'
import Valuation from '../stock/Valuation'
import Receipting from '../receipting/Receipting'
import ReceiptingChecklist from '../receipting/ReceiptingChecklist'
import FullReceiptingHistory from '../receipting/FullReceiptingHistory'
import CreditNotes from '../creditNotes/CreditsNotes'
import ValuationPDF from '../stock/ValuationPDF'
import InvoicesScreen from '../invoices/InvoicesScreen'
import InvoiceView from '../invoices/InvoiceView'
import DataplusScreen from '../dataplusIntegration/dataplusIntegration'
import EnableFunctionality from '../../components/EnableFunctionality'
import GrossProfit from '../orders/GrossProfit'
import ResolvedNotes from '../creditNotes/ResolvedNotes'
import SupplierdasbaordWrapper from './SupplierdasbaordWrapper'
import Customers from '../overview/customers/Customers'
import Analytics from '../overview/analytics/Analytics'
import Feedback from '../overview/feedback/Feedback'
import Subscriptions from '../subscriptions/Subscriptions'
import DeleteDataPage from '../accounts/DeleteDataPage'
import Integrations from '../integrations/Integrations'
import SendToXero from 'src/screens/integrations/xero/SendToXero'
import RecipeDashboard from '../menu-builder/RecipeDashboard'
import OMSCopilot from '../overview/copilot/OMSCopilot'
import EditOrderScreen from '../overview/copilot/EditOrderScreen'

const Routes = () => {
  const venue = useSelector(state => state.venue.venue)
  const account = useSelector(state => state.auth.account)
  const isLoggedIn = useSelector(state => state.auth.isLoggedIn)
  const isAdmin = (account && account.isAdmin) || account.TFRAdmin
  const supplierAdmin = account && account.supplierAdmin
  const isTFRAdmin = account.TFRAdmin

  // fix bug with old email path
  const location = useLocation()
  const params = location.pathname.split('/')
  const emailVenueId = params[2]
  const emailSupplierId = params[3]
  const emailDate = params[4]

  const Redirector = () => <Redirect to={`/v/${venue._id}/purchasing/orders`} />

  return (
    <Switch>
      <Route
        exact
        path='/'
        component={() => (
          <Redirect
            to={
              isLoggedIn && supplierAdmin
                ? '/supplier'
                : isLoggedIn && !supplierAdmin
                ? '/venues'
                : 'login'
            }
          />
        )}
      />
      <Route exact path='/login' component={Login} />
      <Route exact path='/requestDeleteData' component={DeleteDataPage} />
      <Route exact path='/register' component={Register} />
      <Route exact path='/reset-password' component={ResetPassword} />
      <Route
        exact
        path='/checklist/:venueId/:supplierId/:date/:orderNumber'
        component={OrderChecklist}
      />
      <Route
        exact
        path='/receipting/checklist/:venueId/:supplierId/:PONumber'
        component={ReceiptingChecklist}
      />
      <Route
        exact
        path='/invoice/:venueId/:invoiceId/'
        component={InvoiceView}
      />
      {/* redirect from old email link to new with order number */}
      <Route
        exact
        path='/checklist/:venueId/:supplierId/:date'
        component={() => (
          <Redirect
            to={`/checklist/${emailVenueId}/${emailSupplierId}/${emailDate}/all`}
          />
        )}
      />
      <LoggedInRoute isLoggedIn={isLoggedIn}>
        {supplierAdmin ? (
          <Route path='/supplier'>
            {/*<AdminRoute supplierAdmin={supplierAdmin}>*/}
            <Route
              exact
              path='/supplier'
              component={() => <Redirect to='/supplier/orders' />}
            />
            <SupplierdasbaordWrapper
              supplierTitle={account?.supplier[0]?.title}
              location={location}
            >
              <Route
                exact
                path='/supplier/orders'
                component={AdminOrders}
              ></Route>
              {/*component={AdminOrders} />*/}
              <Route exact path='/supplier/spending' component={Spending} />
              <Route exact path='/supplier/products' component={Products} />
              <Route exact path='/supplier/co-pilot' component={OMSCopilot} />
              <Route
                exact
                path='/supplier/co-pilot/:orderId/editOrder'
                component={EditOrderScreen}
              />
              <Route
                exact
                path='/supplier/customers'
                component={Customers}
              ></Route>
              <Route
                exact
                path='/supplier/analytics'
                component={Analytics}
              ></Route>
              <Route
                exact
                path='/supplier/feedback'
                component={Feedback}
              ></Route>
              {/*</AdminRoute>*/}
            </SupplierdasbaordWrapper>
          </Route>
        ) : (
          <DashboardWrapper
            venue={venue}
            isAdmin={isAdmin}
            isTFRAdmin={isTFRAdmin}
          >
            <Route path='/admin'>
              <AdminRoute isAdmin={isAdmin}>
                <Route
                  exact
                  path='/admin'
                  component={() => <Redirect to='/admin/orders' />}
                />
                <Route exact path='/admin/orders' component={AdminOrders} />
                <Route exact path='/admin/spending' component={Spending} />
                <Route exact path='/admin/products' component={Products} />
                <Route exact path='/admin/suppliers' component={Suppliers} />
                <Route exact path='/admin/accounts' component={Accounts} />
              </AdminRoute>
            </Route>

            <Route path='/overview'>
              <OverViewRoute>
                <Route
                  exact
                  path='/overview'
                  component={() => <Redirect to='/overview/pastOrders' />}
                />
                <Route
                  exact
                  path='/overview/pastOrders'
                  component={PastOrders}
                />
                <Route
                  exact
                  path='/overview/pastOrdersspending'
                  component={PastOrdersSpending}
                />
              </OverViewRoute>
            </Route>

            <Route exact path='/venues' component={Venues} />

            <VenueInternalWrapper venue={venue} isAdmin={isAdmin}>
              <Route exact path='/v/:venueId' component={Redirector} />
              <Route path='/v/:venueId/purchasing'>
                <Orders>
                  <Route
                    exact
                    path='/v/:venueId/purchasing/orders'
                    component={Invoices}
                  />
                  <Route
                    exact
                    path='/v/:venueId/purchasing/gross-profit'
                    component={GrossProfit}
                  />
                  <Route
                    exact
                    path='/v/:venueId/purchasing/statement'
                    component={() => <Statement venueId={venue._id} />}
                  />
                  <Route
                    exact
                    path='/v/:venueId/purchasing/basket'
                    component={() => <Basket venueId={venue._id} />}
                  />
                  <Route
                    exact
                    path='/v/:venueId/purchasing/ordered'
                    component={OrderedProducts}
                  />
                  <Route
                    exact
                    path='/v/:venueId/purchasing/spendTime'
                    component={SpendOverTime}
                  />
                  <Route
                    exact
                    path='/v/:venueId/purchasing/invoices'
                    component={() => <InvoicesScreen venueId={venue._id} />}
                  />
                  <Route
                    exact
                    path='/v/:venueId/purchasing/dataplus'
                    component={() => {
                      if (venue.dataplusIntegration) {
                        return (
                          <DataplusScreen
                            venueId={venue._id}
                            venueTitle={venue.title}
                          />
                        )
                      } else {
                        return <EnableFunctionality />
                      }
                    }}
                  />
                  <Route
                    exact
                    path='/v/:venueId/purchasing/receipting'
                    component={() => <Receipting venueId={venue._id} />}
                  />
                  <Route
                    exact
                    path='/v/:venueId/purchasing/receipting/xero'
                    component={() => <SendToXero venueId={venue._id} />}
                  />
                  <Route
                    exact
                    path='/v/:venueId/purchasing/creditnotes'
                    component={() => <CreditNotes venueId={venue._id} />}
                  />
                  <Route
                    exact
                    path='/v/:venueId/purchasing/resolvednotes'
                    component={() => <ResolvedNotes venueId={venue._id} />}
                  />

                  <Route
                    exact
                    path='/v/:venueId/purchasing/credit'
                    component={Credit}
                  />
                </Orders>
              </Route>
              <Route
                exact
                path='/v/:venueId/invoice/:date'
                component={Invoice}
              />
              <Route
                exact
                path='/v/:venueId/stockTakes/:stockTakeId'
                component={Stock}
              />
              <Route
                exact
                path='/v/:venueId/stockTakes/:stockTakeId/valuation-report'
                component={Valuation}
              />
              <Route
                exact
                path='/v/:venueId/stockTakes/:stockTakeId/wastage-report'
                component={Valuation}
              />
              <Route
                exact
                path='/v/:venueId/receipting/history'
                component={FullReceiptingHistory}
              />
              <Route
                exact
                path='/v/:venueId/stockTakes/:stockTakeId/wastage-report-pdf'
                component={ValuationPDF}
              />
              <Route
                exact
                path='/v/:venueId/stockTakes/:stockTakeId/valuation-report-pdf'
                component={ValuationPDF}
              />
              <Route
                exact
                path='/v/:venueId/invoice/:startDate/:endDate'
                component={Invoice}
              />
              <Route
                exact
                path='/v/:venueId/products'
                component={VenueProducts}
              />
              <Route exact path='/v/:venueId/catalogue' component={Catalogue} />
              <Route
                exact
                path='/v/:venueId/suppliers'
                component={VenueSuppliers}
              />
              <Route
                exact
                path='/v/:venueId/stockTakes'
                component={StockList}
              />
              <Route
                exact
                path='/v/:venueId/menu-builder'
                component={RecipeDashboard}
              />
              <Route exact path='/v/:venueId/settings' component={Settings} />
              <Route
                exact
                path='/v/:venueId/subscriptions'
                component={Subscriptions}
              />
              <Route
                exact
                path='/v/:venueId/integrations'
                component={Integrations}
              />
            </VenueInternalWrapper>
          </DashboardWrapper>
        )}
      </LoggedInRoute>
    </Switch>
  )
}

export default Routes
