import { createSlice } from '@reduxjs/toolkit'
import { API } from 'aws-amplify'
import Sentry from 'src/utils/sentry'
let controller = new AbortController() // Ensure it's initialized

const ordersCaptureSlice = createSlice({
  name: 'ordersCapture',
  initialState: {
    emails: [],
    parsedOrders: [],
    parsedOrder: {},
    omsOrders: [],
    isLoading: false,
    showMessage: null,
    approveLoading: false,
    rejectLoading: false,
    editLoading: false,
    parssedLoading: false,
    correctOrderRate: 0,
    averageConfidenceScore: 0,
    trackingOverview: {},
  },
  reducers: {
    setEmails: (state, action) => {
      state.emails = action.payload
    },
    setParsedOrders: (state, action) => {
      state.parsedOrders = action.payload
    },
    setParsedOrder: (state, action) => {
      state.parsedOrder = action.payload
    },
    setOMSOrders: (state, action) => {
      state.omsOrders = action.payload
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload
    },
    setApproveLoading: (state, action) => {
      state.approveLoading = action.payload
    },
    setShowMessage: (state, action) => {
      state.showMessage = action.payload
    },
    setEditLoading: (state, action) => {
      state.editLoading = action.payload
    },
    setParsedLoading: (state, action) => {
      state.parssedLoading = action.payload
    },
    setRejectLoading: (state, action) => {
      state.rejectLoading = action.payload
    },
    setCorrectOrderRate: (state, action) => {
      state.correctOrderRate = action.payload
    },
    setAverageConfidenceScroe: (state, action) => {
      state.averageConfidenceScore = action.payload
    },
    setTrackingOverview: (state, action) => {
      state.trackingOverview = action.payload
    },
  },
})
export const {
  setEmails,
  setParsedOrders,
  setOMSOrders,
  setIsLoading,
  setParsedOrder,
  setShowMessage,
  setApproveLoading,
  setEditLoading,
  setRejectLoading,
  setCorrectOrderRate,
  setAverageConfidenceScroe,
  setParsedLoading,
  setTrackingOverview,
} = ordersCaptureSlice.actions

export const fetchAIOrdersData = username => async dispatch => {
  try {
    dispatch(setIsLoading(true))
    const { data, correctOrderRate, averageConfidenceScore, trackingOverview } =
      await API.get('api2', `orderCapture/${username}`)

    const emails = data?.map(order => order.email)
    dispatch(setParsedOrders(data))
    dispatch(setEmails(emails))
    dispatch(setParsedOrder(data[0]))
    dispatch(setCorrectOrderRate(correctOrderRate))
    dispatch(setAverageConfidenceScroe(averageConfidenceScore))
    dispatch(setTrackingOverview(trackingOverview))
    dispatch(setIsLoading(false))
  } catch (e) {
    console.error(e)
    dispatch(setIsLoading(false))
    Sentry.captureException(e)
    return false
  }
}

export const parseOrder =
  (accountId, orderId, emailId) => async (dispatch, getState) => {
    dispatch(setParsedLoading(true))
    try {
      const { data } = await API.post('api2', `orderCapture/parseOrder`, {
        body: {
          accountId,
          orderId,
          emailId,
        },
      })
      const { parsedOrders } = getState().ordersCapture
      const index = parsedOrders?.findIndex(item => item._id === data._id)
      if (index !== -1) {
        const parsedOrderCopy = [...parsedOrders]
        parsedOrderCopy[index] = data

        dispatch(setParsedOrders(parsedOrderCopy))
      }

      dispatch(setParsedOrder(data))
      dispatch(setParsedLoading(false))
    } catch (e) {
      dispatch(setParsedLoading(false))
      console.error(e)
      Sentry.captureException(e)
      return false
    }
  }

export const getOrdersDetails = orderIds => async dispatch => {
  dispatch(setParsedLoading(true))
  try {
    const { data } = await API.post('api2', `orderCapture/ordersDetails`, {
      body: {
        orderIds,
      },
    })
    dispatch(setOMSOrders(data))
    dispatch(setParsedLoading(false))
  } catch (e) {
    console.error(e)
    dispatch(setParsedLoading(false))
    Sentry.captureException(e)
    return false
  }
}

export const createOMSOrder = body => async dispatch => {
  dispatch(setEditLoading(true))
  try {
    const { message } = await API.post('api2', `orderCapture/createOrder`, {
      body,
    })
    dispatch(setShowMessage(message))
    dispatch(setEditLoading(false))
  } catch (e) {
    console.error(e)
    dispatch(setEditLoading(false))
    Sentry.captureException(e)
    return false
  }
}

export const editOMSOrder = body => async dispatch => {
  body.approveOrder
    ? dispatch(setApproveLoading(true))
    : dispatch(setEditLoading(true))
  try {
    const { message } = await API.put('api2', `orderCapture/editOMSOrder`, {
      body,
    })
    body.approveOrder
      ? dispatch(setApproveLoading(false))
      : dispatch(setEditLoading(false))
    dispatch(setShowMessage(message))
  } catch (e) {
    console.error(e)
    body.approveOrder
      ? dispatch(setApproveLoading(false))
      : dispatch(setEditLoading(false))
    Sentry.captureException(e)
    return false
  }
}
export const approveOrder = (orderId, feedback) => async dispatch => {
  dispatch(setApproveLoading(true))
  try {
    const response = await API.post(
      'api2',
      `orderCapture/${orderId}/approveOrder`,
      {
        body: {
          orderId,
          feedback,
        },
      },
    )
    dispatch(setShowMessage(response?.message))
    dispatch(setApproveLoading(false))
  } catch (e) {
    console.error(e)
    dispatch(setApproveLoading(false))
    Sentry.captureException(e)
    return false
  }
}

export const rejectOrder = (orderId, feedback) => async dispatch => {
  dispatch(setRejectLoading(true))
  try {
    const response = await API.post(
      'api2',
      `orderCapture/${orderId}/rejectOrder`,
      {
        body: {
          orderId,
          feedback,
        },
      },
    )
    dispatch(setShowMessage(response?.message))
    dispatch(setRejectLoading(false))
  } catch (e) {
    console.error(e)
    dispatch(setRejectLoading(false))
    Sentry.captureException(e)
    return false
  }
}

export const fetchVenueSupplierProducts = async supplierId => {
  try {
    // Cancel the previous request only if `controller` exists and is not already aborted
    if (controller instanceof AbortController) {
      controller.abort()
    }

    // Create a new AbortController instance for the new request
    controller = new AbortController()
    const signal = controller.signal

    const response = await API.get(
      'api2',
      `orderCapture/getVenueSupplierProducts?supplierId=${supplierId}`,
      { signal },
    )

    return response?.data
  } catch (e) {
    if (e.name === 'AbortError') {
      console.log('Previous request canceled')
    } else {
      console.error(e)
      Sentry.captureException(e)
      return false
    }
  }
}

export const fetchVenueSuppliers = async (accountId, venueId) => {
  try {
    const response = await API.get(
      'api2',
      `orderCapture/getVenueSuppliers?accountId=${accountId}&venueId=${venueId}`,
    )
    return response?.data
  } catch (e) {
    console.error(e)
    Sentry.captureException(e)
    return false
  }
}

export const deleteOrder = async (parsedOrderId, orderId) => {
  try {
    const response = await API.del(
      'api2',
      `orderCapture/${orderId}/deleteOrder/${parsedOrderId}`,
    )

    console.log('response', response)
  } catch (e) {
    console.error(e)
    Sentry.captureException(e)
    return false
  }
}

export default ordersCaptureSlice
