import { createSlice } from '@reduxjs/toolkit'
import { API, Auth } from 'aws-amplify'
import Sentry from 'src/utils/sentry'

const supplierSlice = createSlice({
  name: 'Supplier',
  initialState: {
    suppliers: [],
    paginatedSuppliers: [],
    isSuppliersLoading: false,
    supplierStats: {
      serverCount: 0,
    },
  },
  reducers: {
    setSuppliers: (state, action) => {
      state.suppliers = action.payload
    },
    setPaginatedSuppliers: (state, action) => {
      state.paginatedSuppliers = action.payload
    },
    setSupplierStats: (state, action) => {
      state.supplierStats.serverCount = action.payload.count
    },
    setSupplierLoading: (state, action) => {
      state.isSuppliersLoading = action.payload
    },
    addSupplierToState: (state, action) => {
      state.suppliers.push(action.payload)
    },
    deleteSupplierFromState: (state, action) => {
      state.suppliers = state.suppliers.filter(
        item => item._id !== action.payload,
      )
    },
    editSupplierInState: (state, action) => {
      const index = state.suppliers.findIndex(
        supplier => supplier._id === action.payload._id,
      )
      if (index !== -1) state.suppliers[index] = action.payload
    },
    setSearchReindexLoading: (state, action) => {
      const supplier = state.suppliers.find(
        supplier => supplier._id === action.payload.id,
      )
      supplier.loadingSearchReindex = action.payload.loading
    },
    setSearchReindexResponse: (state, action) => {
      const supplier = state.suppliers.find(
        supplier => supplier._id === action.payload.id,
      )
      supplier.searchReindexResponse = action.payload.response
    },
    resetSearchReindex: (state, action) => {
      const supplier = state.suppliers.find(
        supplier => supplier._id === action.payload.id,
      )
      supplier.loadingSearchReindex = false
      supplier.searchReindexResponse = null
    },
  },
})

export const {
  setSuppliers,
  setPaginatedSuppliers,
  setSupplierStats,
  setSupplierLoading,
  addSupplierToState,
  deleteSupplierFromState,
  editSupplierInState,
  setSearchReindexLoading,
  setSearchReindexResponse,
  resetSearchReindex,
} = supplierSlice.actions

export const fetchAllSuppliers = () => async dispatch => {
  try {
    const session = await Auth.currentAuthenticatedUser()
    const _suppliers = await API.get('api', `suppliers`, {
      queryStringParameters: {
        username: session.attributes.sub,
      },
    })
    dispatch(setSuppliers(_suppliers))
  } catch (e) {
    console.error(e)
    Sentry.captureException(e)
    return false
  }
}
export const downloadSupplierVenuesCSV = supplierId => async () => {
  try {
    const suppliers = await API.get('api', `suppliers/${supplierId}`)
    console.log('downloadSupplierVenuesCSV', suppliers)
  } catch (e) {
    console.error(e)
    Sentry.captureException(e)
    return false
  }
}

export const addSupplier = supplier => async dispatch => {
  try {
    const _supplier = await API.post('api', `supplier`, {
      body: supplier,
    })
    dispatch(addSupplierToState(_supplier))
  } catch (e) {
    console.error(e)
    Sentry.captureException(e)
    return false
  }
}

export const editSupplier = supplier => async dispatch => {
  try {
    const _supplier = await API.post('api', `supplier`, {
      body: supplier,
    })
    dispatch(editSupplierInState(_supplier))
  } catch (e) {
    console.error(e)
    Sentry.captureException(e)
    return false
  }
}

export const deleteSupplier = supplier => async dispatch => {
  try {
    await API.del('api', `supplier/${supplier._id}`)
    dispatch(deleteSupplierFromState(supplier._id))
  } catch (e) {
    console.error(e)
    Sentry.captureException(e)
    return false
  }
}

export const rebuildSupplierSearchIndex = supplier => async dispatch => {
  try {
    dispatch(setSearchReindexLoading({ id: supplier._id, loading: true }))
    const response = await API.get(
      'api',
      `admin/search/indexProducts/${supplier._id}`,
    )
    dispatch(setSearchReindexLoading({ id: supplier._id, loading: false }))
    dispatch(setSearchReindexResponse({ id: supplier._id, response }))
  } catch (e) {
    console.error(e)
    Sentry.captureException(e)
    dispatch(setSearchReindexLoading({ id: supplier._id, loading: false }))
    dispatch(
      setSearchReindexResponse({
        id: supplier._id,
        response: { success: false },
      }),
    )
    return false
  }
}

export default supplierSlice
