import FadeIn from 'src/components/styled/FadeIn'
import Title from 'src/branding/Title'

import SearchCustomer from 'src/screens/overview/customers/SearchCustomer'
import React, { useState, useEffect } from 'react'
import { Table, Modal, Popconfirm, Button, Form } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { setTerm } from 'src/features/CatalogueSearchSlice'
import EditCustomerModel from './EditCustomerModel'
import CustomerCard from './CustomerCard'
import NumerCustomer from './NumberCustomer'

import {
  adminDeleteCustomer,
  adminEditCustomer,
  fetchAllCustomers,
  adminAddCustomer,
} from 'src/features/CustomerSlice'
import Talk from 'talkjs'

import { getVenueByVenueSupplierId } from 'src/features/VenueSuppliersSlice'
import { downloadCsvTemplate } from 'src/features/downloadCustomerCSVTemplate'

import { notifications } from 'src/features/ChecklistSlice'
import { parserCustomerDataCSV } from 'src/features/parserCustomerDataCSV'
import InfoPanel from './InfoPanel'

const Customers = ({ form }) => {
  const dispatch = useDispatch()
  const customers = useSelector(state => state.customer?.customers || [])
  const searchTitle = useSelector(state => state.customerSearch.title)
  const searchCustomers = useSelector(state => state.customerSearch.customers)
  const searchLoading = useSelector(state => state.customerSearch.loading)
  const account = useSelector(state => state.auth.account)
  const pageLoading = useSelector(state => state.customer.isLoading)

  const supplierId = account?.supplier[0]._id

  const [editingCustomer, setEditingCustomer] = useState(null)
  const [addCustomerModalVisible, setAddCustomerModalVisible] = useState(false)

  const [previous, setPrevious] = useState(null)

  const emptyCustomer = {
    emailOfCustomer: '',
    accountName: '',
    venueName: '',
    accountNumber: '',
    addressCity: '',
    addressLine1: '',
    addressLine2: '',
    addressPostCode: '',
    deliveryDays: [],
    minimumOrder: 0,
    deliveryFee: 0,
    orderCutOffTime: '',
    supplierid: 0,
  }
  const [addingCustomer, setAddingCustomer] = useState(emptyCustomer)

  useEffect(() => {
    dispatch(fetchAllCustomers(supplierId))
  }, [dispatch])

  const isSearchMode = Boolean(searchTitle.trim())

  const displayCustomer = (isSearchMode ? searchCustomers : customers) || []

  const { getFieldDecorator } = form
  const hadlerAddAction = () => {
    form.validateFields(error => {
      if (!error) {
        dispatch(adminAddCustomer(supplierId, addingCustomer))
        setAddCustomerModalVisible(false)
        setAddingCustomer(emptyCustomer)
      }
    })
  }

  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(100)
  const paginatedProducts = displayCustomer.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize,
  )
  const paginationConfig = {
    current: currentPage,
    pageSize: pageSize,
    total: displayCustomer.length,
    showSizeChanger: true,
    pageSizeOptions: ['5', '10', '20', '50', '100', '250'],
    onChange: page => setCurrentPage(page),
    onShowSizeChange: (_, size) => {
      setPageSize(size)
      setCurrentPage(1)
    },
    showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} customer`,
  }

  const handleConversation = async element => {
    var venue = await getVenueByVenueSupplierId(element)

    Talk.ready
      .then(() => {
        const me = new Talk.User({
          id: 'oms-support',
          name: 'Orders Made Simple Support',
          welcomeMessage: 'Hey there! How are you? :-)',
          role: 'default',
        })

        if (previous) {
          previous.destroy()
        }

        const session = new Talk.Session({
          appId: 'GR4DzdvG',
          me: me,
        })

        const other = new Talk.User({
          id: venue._id,
          name: venue.title,
          welcomeMessage: 'Hey there! Send us a message to start a chat 😁',
          role: 'venues',
        })

        const conversation = session.getOrCreateConversation(
          Talk.oneOnOneId(me, other),
        )

        conversation.setParticipant(me)
        conversation.setParticipant(other)

        const popup = session.createPopup()

        setPrevious(popup)
        popup.select(conversation)
        popup.onClose(() => popup.destroy())
        popup.onSendMessage(() => {
          try {
            notifications(venue._id, 'OMS Support')
          } catch (e) {
            console.log(e)
          }
        })
        popup.mount({ show: true })
        return
      })
      .catch(err => console.log(err))
  }

  const [selectedFile, setSelectedFile] = useState(null)

  const handleFileChange = event => {
    const file = event.target.files[0]

    if (file && file.type === 'text/csv') {
      setSelectedFile(file)

      const reader = new FileReader()
      reader.onload = e => {
        const fileContent = e.target.result
        parserCustomerDataCSV(fileContent, dispatch)
      }
      reader.onerror = () => {
        alert('Error reading the file.')
      }
      reader.readAsText(file)
      event.target.value = ''
    } else {
      alert('Please select a valid CSV file.')
      setSelectedFile(null)
    }
  }

  const handleButtonClick = () => {
    document.getElementById('csvFileInput').click()
  }

  return (
    <Wrapper>
      <Title>Customers</Title>
      <WhiteLine>
        <ButtonContainer>
          <LeftButtons>
            <button onClick={() => downloadCsvTemplate()}>
              Download CSV Template
            </button>

            <button
              onClick={() => {
                handleButtonClick()
              }}
            >
              Add Customers Via CSV
            </button>

            <input
              id='csvFileInput'
              type='file'
              accept='.csv'
              style={{ display: 'none' }}
              onChange={handleFileChange}
            />
            {selectedFile && <p>Selected File: {selectedFile.name}</p>}
          </LeftButtons>
          <RightButton>
            <button onClick={() => setAddCustomerModalVisible(true)}>
              Add Customer Manually
            </button>
          </RightButton>
        </ButtonContainer>
      </WhiteLine>

      <SearchContainer>
        <SearchCustomer supplierId={supplierId} />
      </SearchContainer>

      <NumerCustomer arryCusotmer={displayCustomer} />

      <Table
        scroll={{
          scrollToFirstRowOnChange: true,
          x: true,
        }}
        dataSource={paginatedProducts.map(customer => ({
          key: customer._id,
          ...customer,
        }))}
        pagination={paginationConfig}
        loading={pageLoading || searchLoading}
        columns={[
          {
            title: 'Logo',
            key: 'logo',
            className: 'restrictShrink',
            render: record => {
              const { logo } = record
              return (
                <img
                  src={logo === 'No logo' ? '/apple-touch-icon.png' : logo}
                  alt='logo'
                  style={{ width: '50px', height: '50px', borderRadius: '5px' }}
                ></img>
              )
            },
          },
          {
            title: 'Venue name',
            dataIndex: 'venueTitle',
            key: 'venueTitle',
            className: 'restrictShrink',
          },
          {
            title: 'Account Number',
            dataIndex: 'accountNumber',
            key: 'accountNumber',
            className: 'restrictShrink',
          },
          {
            title: 'Address',
            key: 'address',
            className: 'restrictShrink',
            render: record => {
              const {
                addressCity,
                addressLine1,
                addressLine2,
                addressPostCode,
              } = record

              const fullAddress = [
                addressLine1,
                addressLine2,
                addressCity,
                addressPostCode,
              ]
                .filter(Boolean)
                .join(', ')
              return fullAddress || 'No address provided'
            },
          },
          {
            title: 'Delivery Days',
            dataIndex: 'deliveryDays',
            key: 'deliveryDays',
            className: 'restrictShrink',
            render: deliveryDays => {
              return deliveryDays.join(', ')
            },
          },
          {
            title: 'Minimum Order',
            dataIndex: 'minimumOrder',
            className: 'restrictShrink',
          },
          {
            title: 'Delivery Free',
            dataIndex: 'deliveryFree',
            className: 'restrictShrink',
          },
          {
            title: 'Order Cut Off',
            dataIndex: 'orderCutOff',
            key: 'orderCutOff',
            className: 'restrictShrink',
            render: orderCutOff => {
              try {
                return orderCutOff
              } catch (error) {
                console.error('Error formatting date:', error)
                return 'Invalid date'
              }
            },
          },
          {
            title: 'Price File',

            key: 'priceFile',
            className: 'restrictShrink',
            render: render => {
              if (!render) return null
              const { titlePriceFile, secondTitlePriceFile } = render
              const fullTitle = [titlePriceFile, secondTitlePriceFile]
                .filter(Boolean)
                .join(' ')

              return fullTitle || 'No Title'
            },
          },
          {
            title: 'Message',
            key: 'message',
            className: 'restrictShrink',
            render: (_, customer) => (
              <img
                src='/customerChat.png'
                alt='Open customer chat'
                style={{
                  borderWidth: '2px',
                  cursor: 'pointer',
                  width: '32px',
                  height: '32px',
                }}
                onClick={() => {
                  if (!customer?._id) {
                    console.error('Customer ID is missing')
                    return
                  }
                  handleConversation(customer._id)
                }}
              />
            ),
          },

          {
            title: 'Action',
            key: 'action',
            className: 'restrictShrink',
            render: (_, customer) => (
              <>
                <StyledButton
                  type={'dashed'}
                  onClick={() => {
                    setEditingCustomer(customer)
                  }}
                >
                  <span>Edit</span>
                </StyledButton>

                {'    '}
                <Popconfirm
                  title='Are you sure you want to delete this customer?'
                  placement='topRight'
                  onConfirm={() => {
                    dispatch(adminDeleteCustomer(customer, supplierId))
                    dispatch(setTerm(''))
                  }}
                  okText='Yes'
                  cancelText='No'
                >
                  <StyledButton type={'danger'}>
                    <span>Delete</span>
                  </StyledButton>
                </Popconfirm>
              </>
            ),
          },
        ]}
      />

      {editingCustomer && (
        <Modal
          title='Edit Customer'
          visible={!!editingCustomer}
          okText='Save'
          onCancel={() => setEditingCustomer(null)}
          onOk={() => {
            if (editingCustomer) {
              const updatedCustomer = { ...editingCustomer }

              dispatch(
                adminEditCustomer(
                  supplierId,
                  updatedCustomer._id,
                  updatedCustomer,
                ),
              )

              setEditingCustomer(null)
            }
          }}
        >
          <EditCustomerModel
            customer={editingCustomer}
            setEditedCustomer={customer => {
              setEditingCustomer({ ...customer })
            }}
          />
        </Modal>
      )}

      <Modal
        title='Add Customer'
        visible={addCustomerModalVisible}
        okText='Save'
        onOk={hadlerAddAction}
        onCancel={() => {
          setAddCustomerModalVisible(false)
        }}
      >
        <div style={{ display: 'flex', gap: '100px' }}>
          <div style={{ flex: 1 }}>
            <CustomerCard
              addingCustomer={addingCustomer}
              setAddingCustomer={setAddingCustomer}
              getFieldDecorator={getFieldDecorator}
            />
          </div>

          <InfoPanel
            supplierId={supplierId}
            addCustomerModalVisible={addCustomerModalVisible}
            searchVenueName={addingCustomer.venueName}
            addingCustomer={addingCustomer}
            setAddingCustomer={setAddingCustomer}
          />
        </div>
      </Modal>
    </Wrapper>
  )
}

const SearchContainer = styled.div`
  position: relative;
  top: 80%;
  margin-top: 30px;
  margin-left: 5px;
`

const Wrapper = styled(FadeIn)`
  .restrictShrink {
    min-width: 110px;
  }
`

const WhiteLine = styled.div`
  position: relative;
  background-color: white;
  width: 99%;
  height: 80px;
  margin: 10px auto;
  display: flex;
  align-items: center;
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 10px;
  button {
    background-color: ${({ theme }) => theme.colours.lettuceGreen || 'green'};
    color: white;
    border: none;
    border-radius: 10px;
    padding: 10px 15px;
    cursor: pointer;
    font-size: 14px;

    &:hover {
      background-color: #b5d922;
      opacity: 0.8;
      cursor: pointer;
    }
  }
`

const LeftButtons = styled.div`
  display: flex;
  gap: 10px;
`

const StyledButton = styled(Button)`
  margin: 3px 3px 3px 0;
  width: 80px;
`

const RightButton = styled.div``

const WrappedAddCustomer = Form.create({ name: 'add_customer' })(Customers)
export default WrappedAddCustomer
