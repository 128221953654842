import { API, Auth } from 'aws-amplify'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import LoadingScreen from 'src/components/shared/LoadingScreen'
import FadeIn from 'src/components/styled/FadeIn'
import { setUser } from 'src/features/AuthSlice'
import Routes from 'src/screens/app/Routes'
import Sentry from 'src/utils/sentry'
import waitAtLeast from 'src/utils/waitAtLeast'
import useSocket from 'src/utils/useSocket'
import { SuccessNotification } from 'src/notifications/SuccessNotification'

const App = () => {
  const dispatch = useDispatch()
  const [loaded, setLoaded] = useState(false)
  const accountId = useSelector(state => state.auth.account._id)
  const socket = useSocket(accountId)
  // const [notifications, setNotifications] = useState([]);
  // console.log("notifications ------> ",notifications);

  useEffect(() => {
    if (socket) {
      socket.on('notification', data => {
        console.log('Notification received:', data)
        SuccessNotification({ message: data.message })
        setTimeout(() => window.location.reload(), 2000)
      })
      return () => {
        socket.off('notification')
      }
    }
  }, [socket])

  useEffect(() => {
    const loadUser = async () => {
      const refresh = localStorage.getItem('refresh')

      if (!refresh) {
        localStorage.setItem('refresh', 'true')
        window.location.reload()
        return
      }

      try {
        const user = await Auth.currentAuthenticatedUser().catch(e => {
          console.log(e)
          Sentry.captureException(e)
          return false
        })

        if (user) {
          const account = await waitAtLeast(
            800,
            API.get('api', `account/${user.attributes.sub}`),
          )

          dispatch(
            setUser({
              isLoggedIn: true,
              account: account,
              cognito: {
                name: user.attributes['Name'],
                username: user.attributes.sub,
              },
            }),
          )

          window &&
            window.LogRocket &&
            window.LogRocket.identify(user.attributes.sub, {
              name: user.attributes['Name'],
              email: user.attributes.email,
            })

          setLoaded(true)
        } else {
          setTimeout(() => setLoaded(true), 600)
        }
      } catch (e) {
        console.error(e)
        Sentry.captureException(e)
        setLoaded(true)
      }
    }

    loadUser()
  }, [dispatch])

  return <FadeIn>{loaded ? <Routes /> : <LoadingScreen />}</FadeIn>
}

export default App
