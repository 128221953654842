import { API, Auth } from 'aws-amplify'
import config from 'src/config'

export const uploadFile = async selectedFile => {
  const session = await Auth.currentSession()
  const token = session.getAccessToken().getJwtToken()

  const formData = new FormData()
  formData.append('file', selectedFile)

  try {
    const response = await fetch(
      `${config.apiGateway.EXPRESS_URL}files/uploadFile`,
      {
        method: 'POST',
        body: formData,
        headers: {
          Authorization: `${token}`,
        },
      },
    )

    const res = await response.json()
    if (res.success) {
      return res.data
    }
  } catch (error) {
    console.error('Error uploading file:', error)
  }
}

export const deleteFile = async fileKey => {
  if (!fileKey) return

  try {
    const response = await API.del('api2', `files/deleteFile/${fileKey}`)

    if (!response.success) {
      throw new Error('Failed to delete file')
    }

    return true
  } catch (error) {
    console.error('Error deleting file:', error)
    return false
  }
}
