import AntDIcon from 'antd/lib/icon'
import React from 'react'

const Icon = ({ size }) => {
  const SVG = () => (
    <svg
      width={size}
      height={size}
      viewBox='-1.6 -1.6 19.20 19.20'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      className='si-glyph si-glyph-bullet-checked-list'
      fill='#000000'
    >
      <g id='SVGRepo_bgCarrier' strokeWidth='0'></g>
      <g
        id='SVGRepo_tracerCarrier'
        strokeLinecap='round'
        strokeLinejoin='round'
        stroke='#CCCCCC'
        strokeWidth='0.096'
      ></g>
      <g id='SVGRepo_iconCarrier'>
        <title>112</title>
        <defs></defs>
        <g strokeWidth='0.00016' fill='none' fillRule='evenodd'>
          <g transform='translate(0.000000, 2.000000)' fill='#000000'>
            <g transform='translate(0.000000, 1.000000)'>
              <path
                d='M3.016,6 L0.0159999999,6 L0.0159999999,3 L3.016,3 L3.016,6 L3.016,6 Z M0.953,5 L1.953,5 L1.953,3.969 L0.953,3.969 L0.953,5 L0.953,5 Z'
                className='si-glyph-fill'
              ></path>
              <path
                d='M2.969,9.986 L0.0159999999,9.986 L0.0159999999,7 L2.969,7 L2.969,9.986 L2.969,9.986 Z M0.954,9 L1.954,9 L1.954,8 L0.954,8 L0.954,9 L0.954,9 Z'
                className='si-glyph-fill'
              ></path>
              <rect
                x='5'
                y='0'
                width='10.977'
                height='0.976'
                className='si-glyph-fill'
              ></rect>
              <rect
                x='5'
                y='4'
                width='10.977'
                height='0.96'
                className='si-glyph-fill'
              ></rect>
              <rect
                x='5'
                y='8'
                width='10.977'
                height='0.914'
                className='si-glyph-fill'
              ></rect>
            </g>
            <path
              d='M1.366,3.295 L0.0209999999,1.939 L0.656,1.304 L1.366,2 L3.402,0 L4.025,0.636 L1.366,3.295 Z'
              className='si-glyph-fill'
            ></path>
          </g>
        </g>
      </g>
    </svg>
  )
  return <AntDIcon component={SVG} />
}
export default Icon
