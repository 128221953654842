import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import {
  Card,
  Table,
  Button,
  Form,
  Input,
  InputNumber,
  Tag,
  Avatar,
  Typography,
  Checkbox,
} from 'antd'
import { useSelector } from 'react-redux'
const { Text } = Typography
import { Modal } from 'antd'

const StyledCard = styled(Card)`
  margin-top: 24px;
`

const StyledModal = styled(Modal)`
  .ant-modal-close {
    top: -10px; /* Move the close button upwards */
    right: -10px; /* Move it slightly right */
    background: white;
    border-radius: 50%;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
  }

  .ant-modal-close:hover {
    background: #f0f0f0;
  }

  .ant-modal-content {
    padding: 0;
    background: transparent;
    box-shadow: none;
  }
`

const RecipeDetails = ({ recipe, onSave }) => {
  const [isEditing, setIsEditing] = useState(false)
  const { isLoading } = useSelector(state => state.recipes)
  const [formData, setFormData] = useState({
    title: recipe.title,
    description: recipe.description,
    category: recipe.category?.name || '',
    sellingPrice: recipe.sellingPrice,
    grossProfit: recipe?.grossProfit || 0,
    costPerPortion: recipe.costPerPortion,
    costPerRecipe: recipe.costPerRecipe ?? recipe.costPerPortion,
    portions: recipe.portions ?? 1,
  })
  const [ingredients, setIngredients] = useState(recipe.ingredients || [])
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [selectedImage, setSelectedImage] = useState(null)

  useEffect(() => {
    if (recipe.ingredients?.length >= 0) {
      setIngredients(recipe.ingredients)
    }
  }, [recipe.ingredients])

  // Extract allergens
  // Extract unique allergens
  const getAllergens = () => {
    const allergenSet = new Set()

    recipe.ingredients.forEach(ing => {
      if (ing?.product?.allergens) {
        ing.product.allergens.forEach(allergen => allergenSet.add(allergen))
      }
    })

    console.log('Unique Allergens:', Array.from(allergenSet))
    return Array.from(allergenSet)
  }

  const handleSave = () => {
    onSave({
      _id: recipe?._id,
      title: formData.title,
      description: formData.description,
      sellingPrice: formData.sellingPrice,
      grossProfit: formData.grossProfit,
    })
    setIsEditing(false)
  }

  const handleChange = e => {
    const { name, value } = e.target
    setFormData(prevData => ({
      ...prevData,
      [name]: value,
    }))
  }

  const handleSellingPriceChange = value => {
    setFormData(prevData => {
      const newGrossProfit =
        value > 0 ? ((value - prevData.costPerPortion) / value) * 100 : 0
      return {
        ...prevData,
        sellingPrice: value,
        grossProfit: newGrossProfit.toFixed(2),
      }
    })
  }

  const openImageModal = image => {
    setSelectedImage(image)
    setIsModalVisible(true)
  }

  return (
    <StyledCard
      title={formData.title}
      extra={
        isEditing ? (
          <Button loading={isLoading} type='primary' onClick={handleSave}>
            Save Changes
          </Button>
        ) : (
          <Button type='primary' onClick={() => setIsEditing(true)}>
            Edit Recipe
          </Button>
        )
      }
    >
      {recipe.image && (
        <Avatar
          src={recipe.image}
          alt={recipe.title}
          size={250}
          style={{ marginBottom: '16px' }}
        />
      )}

      {isEditing ? (
        <Form layout='vertical'>
          <Form.Item label='Recipe Title'>
            <Input
              name='title'
              value={formData.title}
              onChange={handleChange}
            />
          </Form.Item>
          <Form.Item label='Description'>
            <Input.TextArea
              name='description'
              value={formData.description}
              onChange={handleChange}
            />
          </Form.Item>
          <Form.Item label='Selling Price (£)'>
            <InputNumber
              name='sellingPrice'
              value={formData.sellingPrice}
              onChange={handleSellingPriceChange}
              min={0}
              step={0.01}
            />
          </Form.Item>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              fontSize: 20,
            }}
          >
            <Text strong>Gross Profit %:</Text>
            <Text strong>{formData.grossProfit}%</Text>
          </div>
        </Form>
      ) : (
        <>
          <p>{formData.description}</p>
          <p>
            <strong>Category:</strong> {recipe.category?.name}
          </p>
          <p>
            <p>
              <strong>Cost Per Recipe:</strong> £
              {formData?.costPerRecipe?.toFixed(2)}
            </p>
            <p>
              <strong>Portions:</strong>
              {formData.portions}
            </p>
            <p>
              <strong>Cost Per Portion:</strong> £
              {formData.costPerPortion.toFixed(2)}
            </p>
            <strong>Selling Price:</strong> £{formData.sellingPrice.toFixed(2)}
          </p>
          <p>
            <strong>Gross Profit:</strong>{' '}
            <Tag
              style={{ marginLeft: 10 }}
              color={formData.grossProfit > 0 ? 'green' : 'red'}
            >
              {formData.grossProfit}%
            </Tag>
          </p>
        </>
      )}

      <h3>Ingredients</h3>
      <Table
        dataSource={ingredients}
        columns={[
          {
            title: 'Ingredient',
            dataIndex: ['product', 'title'],
            key: 'title',
          },
          {
            title: 'Quantity',
            dataIndex: 'size',
            key: 'size',
          },
          {
            title: 'UOM',
            dataIndex: ['product', 'UoM'],
            key: 'UoM',
          },
          {
            title: 'Price (£)',
            dataIndex: ['product', 'price'],
            key: 'price',
            render: price => `£${parseFloat(price / 100).toFixed(2)}`,
          },
        ]}
        pagination={false}
      />

      <h3>Notes</h3>
      {recipe.notes ? (
        <>
          <p>{recipe.notes.text}</p>
          <div style={{ display: 'flex', flexWrap: 'wrap', gap: '8px' }}>
            {recipe.notes.images.map((image, index) => (
              <img
                key={index}
                src={image}
                alt={`note-image-${index}`}
                width={100}
                height={100}
                style={{ borderRadius: '8px', cursor: 'pointer' }}
                onClick={() => openImageModal(image)}
              />
            ))}
          </div>
        </>
      ) : (
        <p>No notes available.</p>
      )}

      <h3>Steps</h3>
      {recipe.steps && recipe.steps.length > 0 ? (
        <ul>
          {recipe.steps.map((step, index) => (
            <li key={index} style={{ marginBottom: '8px', listStyle: 'none' }}>
              <Checkbox>{step}</Checkbox>
            </li>
          ))}
        </ul>
      ) : (
        <p>No steps available.</p>
      )}

      <h3>Allergens</h3>
      {getAllergens().length > 0 ? (
        getAllergens().map((allergen, index) => (
          <Tag key={index} color='red'>
            {allergen}
          </Tag>
        ))
      ) : (
        <p>No allergens listed</p>
      )}

      <StyledModal
        visible={isModalVisible}
        footer={null}
        onCancel={() => setIsModalVisible(false)}
        centered
      >
        <img
          src={selectedImage}
          alt='Enlarged Note'
          style={{ width: '100%' }}
        />
      </StyledModal>
    </StyledCard>
  )
}

export default RecipeDetails
