import React, { useState, useEffect } from 'react'
import { AutoComplete, Input, Button, notification } from 'antd'
import styled from 'styled-components'
import dayjs from 'dayjs'
import { useDispatch, useSelector } from 'react-redux'
import {
  createOMSOrder,
  editOMSOrder,
  // fetchAIOrdersData,
  fetchVenueSupplierProducts,
  fetchVenueSuppliers,
  getOrdersDetails,
  // setParsedOrders,
  setShowMessage,
} from 'src/features/OrdersCaptureSlice'
// import { SuccessNotification } from 'src/notifications/SuccessNotification'
import ProductCard from './ProductCard'
import AddProduct from './AddProduct'
import stringSimilarity from 'string-similarity'
const { Option } = AutoComplete
import { useParams } from 'react-router-dom'
import { fetchAllCustomers } from 'src/features/CustomerSlice'
import { API } from 'aws-amplify'

const EditOrderScreen = () => {
  const { orderId } = useParams()
  // const { parsedOrders } = useSelector(state => state.ordersCapture)
  const [selectedData, setSelectedData] = useState(null)
  const [feedback, setFeddback] = useState('')

  const [editedOmsOrders, setEditedOmsOrders] = useState([])
  const [editedOrder, setEditedOrder] = useState({
    orderId: null,
    supplier: null,
    venue: null,
    deliveryDate: '',
    products: [],
  })
  const customerVenues = useSelector(state => state.customer.customers) || []
  const { approveLoading, editLoading, showMessage, omsOrders } = useSelector(
    state => state.ordersCapture,
  )
  const dispatch = useDispatch()
  // const username = useSelector(state => state.auth.cognito.username)
  const [venueSuppliers, setVenueSuppliers] = useState([])
  const [supplierProducts, setSupplierProducts] = useState([])
  const accountId = useSelector(state => state.auth.account._id)
  const [newProducts, setNewProducts] = useState([])
  const [addProduct, setAddProduct] = useState(false)
  const [selectedVenue, setSelectedVenue] = useState('')
  const [supplierTitle, setSupplierTitle] = useState('')
  const account = useSelector(state => state.auth.account)
  const supplierId = account?.supplier[0]._id

  useEffect(() => {
    const getAIOrder = async () => {
      const { aiOrder } = await API.get(
        'api2',
        `orderCapture/${orderId}/getAIOrder`,
      )
      setSelectedData(aiOrder)
    }
    getAIOrder()
  }, [orderId])

  useEffect(() => {
    dispatch(fetchAllCustomers(supplierId))
  }, [dispatch, supplierId])

  useEffect(() => {
    if (selectedData && selectedData?.order?.orderIds?.length > 0) {
      dispatch(getOrdersDetails(selectedData?.order?.orderIds))
    }
  }, [selectedData])

  useEffect(() => {
    if (selectedData?.order?.orderIds?.length > 0 && omsOrders.length > 0) {
      const venue = omsOrders[0]?.venue?._id
      const supplier = omsOrders[0]?.supplier?._id
      const orders = omsOrders.map(order => ({
        ...order,
        venue,
        supplier,
      }))
      setSelectedVenue(omsOrders[0]?.venue?.title)
      setEditedOmsOrders(orders)
    } else if (selectedData?.order) {
      setEditedOrder({
        ...editedOrder,
        orderId: selectedData?._id,
        venue: selectedData?.order?.venue?._id,
        deliveryDate: selectedData?.order?.order?.delivery_date
          ? dayjs(selectedData?.order?.order?.delivery_date).format(
              'YYYY-MM-DD',
            )
          : '',
      })
      setSelectedVenue(selectedData?.order?.venue?.title || '')
    }
    const supplier = omsOrders[0]?.supplier?._id || editedOrder.supplier
    if (supplier) {
      getSupplierProducts(supplier)
    }
  }, [selectedData, omsOrders])

  useEffect(() => {
    const fetchSuppliersAndProducts = async () => {
      if (selectedData?.order?.venue?._id) {
        const venueId = selectedData.order.venue._id
        const suppliers = await fetchVenueSuppliers(accountId, venueId)
        setVenueSuppliers(suppliers)

        if (suppliers.length > 0) {
          const supplierId = suppliers[0]._id
          handleSupplierChange(supplierId)
          await getSupplierProducts(supplierId)
        }
      }
    }

    fetchSuppliersAndProducts()
  }, [selectedData?.order?.venue?._id])

  useEffect(() => {
    if (
      !selectedData?.order?.orderIds?.length &&
      selectedData?.order?.order?.products &&
      !omsOrders.length
      // supplierProducts.length > 0
    ) {
      const updatedProducts = selectedData?.order?.order?.products?.map(
        orderProduct => {
          const foundProduct = supplierProducts.find(product => {
            return (
              stringSimilarity.compareTwoStrings(
                product.title,
                orderProduct.title,
              ) >= 0.5
            )
          })
          return foundProduct
            ? {
                ...foundProduct,
                id: foundProduct._id + Math.random().toString(36).substring(7),
              }
            : orderProduct
        },
      )
      setNewProducts(updatedProducts)
    }
  }, [selectedData, supplierProducts])

  useEffect(() => {
    if (
      selectedData?.order?.orderIds?.length > 0 &&
      venueSuppliers.length > 0
    ) {
      const foundSupplier = venueSuppliers.find(
        supplier => supplier._id === editedOmsOrders[0]?.supplier,
      )
      setSupplierTitle(foundSupplier ? foundSupplier.title : '')
    } else if (editedOrder && editedOrder.supplier) {
      const foundSupplier = venueSuppliers.find(
        supplier => supplier._id === editedOrder?.supplier,
      )
      setSupplierTitle(foundSupplier ? foundSupplier.title : '')
    } else {
      setSupplierTitle(
        omsOrders[0]?.supplier?.title || venueSuppliers[0]?.title,
      )
    }
  }, [editedOmsOrders, venueSuppliers, omsOrders, editedOrder])

  const getSupplierProducts = async supplier => {
    const products = await fetchVenueSupplierProducts(supplier)
    setSupplierProducts(products)
  }

  const handleAddProduct = products => {
    setNewProducts(products)
    setAddProduct(false)
  }

  const handleRemoveProduct = productId => {
    setNewProducts(prev => prev.filter(p => (p.id ?? p._id) !== productId))
    setAddProduct(false)
  }

  const handleOmsOrderChange = (index, field, value) => {
    const updatedOmsOrders = [...editedOmsOrders]
    updatedOmsOrders[index] = {
      ...updatedOmsOrders[index],
      [field]: value,
    }
    setEditedOmsOrders(updatedOmsOrders)
  }

  const handleOmsProductChange = (index, updatedProduct) => {
    setEditedOmsOrders(prevOrders => {
      const updatedOrders = [...prevOrders]
      updatedOrders[index] = updatedProduct
      return updatedOrders
    })
  }

  const handleVenueChange = async value => {
    if (selectedData?.order?.orderIds?.length > 0) {
      setEditedOmsOrders(prevOrders =>
        prevOrders.map(order => ({
          ...order,
          venue: value,
        })),
      )
    } else {
      setEditedOrder(previousState => ({
        ...previousState,
        venue: value,
      }))
    }

    const suppliers = await fetchVenueSuppliers(accountId, value)
    setVenueSuppliers(suppliers)
    if (suppliers.length > 0) {
      handleSupplierChange(suppliers[0]._id)
      await getSupplierProducts(suppliers[0]._id)
    }
  }

  const handleSupplierChange = value => {
    if (selectedData?.order?.orderIds?.length > 0) {
      setEditedOmsOrders(prevOrders =>
        prevOrders.map(order => ({
          ...order,
          supplier: value,
        })),
      )
    } else {
      setEditedOrder(previousState => ({
        ...previousState,
        supplier: value,
      }))
    }
  }

  const handleChangeDeliveryDate = value => {
    if (selectedData?.order?.orderIds?.length > 0) {
      const formattedDate = dayjs(value).toISOString()
      const updatedOmsOrders = editedOmsOrders.map(order => ({
        ...order,
        requiredDeliveryDay: formattedDate,
      }))
      setEditedOmsOrders(updatedOmsOrders)
    } else {
      setEditedOrder(previousState => ({
        ...previousState,
        deliveryDate: value,
      }))
    }
  }

  const handleSaveChanges = approveOrder => {
    const allProductsValid = newProducts.every(product =>
      supplierProducts.some(
        supplierProduct => supplierProduct._id === product._id,
      ),
    )

    if (!allProductsValid) {
      notification.error({
        message: 'Invalid Products',
        description: 'Some products are invalid. Please check and try again.',
      })
      return
    }

    newProducts.forEach(product => delete product.id)

    const payload =
      selectedData?.order?.orderIds?.length > 0
        ? {
            orderIds: selectedData?.order?.orderIds,
            ordersData: editedOmsOrders,
            newProducts,
            orderId: selectedData?._id,
            accountId,
            feedback,
            approveOrder,
          }
        : {
            ...editedOrder,
            products: newProducts,
            accountId,
            feedback,
            approveOrder,
          }

    const action =
      selectedData?.order?.orderIds?.length > 0 ? editOMSOrder : createOMSOrder
    dispatch(action(payload))
  }

  useEffect(() => {
    if (!editLoading && showMessage) {
      setAddProduct(false)
      // setEditedOmsOrders([])
      // setNewProducts([])
      // setSelectedVenue('')
      // setEditedOrder({
      //   ...editedOrder,
      //   orderId: selectedData?._id,
      //   deliveryDate: selectedData?.order?.order?.delivery_date
      //     ? dayjs(selectedData?.order?.order?.delivery_date).format(
      //         'YYYY-MM-DD',
      //       )
      //     : '',
      // })
      dispatch(setShowMessage(null))
    }
  }, [showMessage, editLoading])

  return (
    <div>
      {selectedData?.order?.orderIds?.length > 0 ? (
        <Card>
          <h3>Edit Order Details</h3>
          <h4>Venue</h4>
          <AutoComplete
            showSearch
            value={selectedVenue || omsOrders[0]?.venue?.title}
            onSelect={value => {
              setVenueSuppliers([])
              setSupplierProducts([])
              const data = customerVenues.find(venue => venue._id === value)
              setSelectedVenue(data?.title || data?.venueTitle)
              handleVenueChange(value)
            }}
            placeholder='Search venues'
            style={{ width: '100%' }}
          >
            {customerVenues?.map(venue => (
              <Option key={venue?._id} value={venue?._id}>
                {venue?.title || venue?.venueTitle}
              </Option>
            ))}
          </AutoComplete>
          <h4 style={{ marginTop: 10 }}>Assigned Price File</h4>
          {venueSuppliers && (
            <Input style={{ marginBottom: 20 }} value={supplierTitle} />
          )}
          <FormItem>
            <label>Delivery Date</label>
            <Input
              type='date'
              value={dayjs(editedOmsOrders[0]?.requiredDeliveryDay).format(
                'YYYY-MM-DD',
              )}
              onChange={e => handleChangeDeliveryDate(e.target.value)}
            />
          </FormItem>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <h3>Products</h3>
            <Button
              disabled={!supplierProducts.length}
              onClick={() => setAddProduct(true)}
              style={{ margin: 5, marginBottom: 25 }}
              type={'primary'}
            >
              Add product
            </Button>
          </div>

          {editedOmsOrders?.map((order, index) => (
            <ProductCard
              setSelectedData={setSelectedData}
              key={index}
              order={order}
              index={index}
              handleOmsOrderChange={handleOmsOrderChange}
              supplierProducts={supplierProducts}
              editedOmsOrders={editedOmsOrders}
              setEditedOmsOrders={setEditedOmsOrders}
              selectedData={selectedData}
              handleOmsProductChange={handleOmsProductChange}
            />
          ))}

          {newProducts?.map((product, index) => (
            <AddProduct
              key={product._id}
              supplierProducts={supplierProducts}
              handleAddProduct={handleAddProduct}
              handleRemoveProduct={handleRemoveProduct}
              newProducts={newProducts}
              product={product}
              index={index}
            />
          ))}

          {addProduct && (
            <AddProduct
              supplierProducts={supplierProducts}
              handleAddProduct={handleAddProduct}
              handleRemoveProduct={handleRemoveProduct}
              newProducts={newProducts}
            />
          )}
          <div>
            <h4>Add Notes:</h4>
            <textarea
              onChange={e => setFeddback(e.target.value)}
              placeholder='Write any comments or notes here...'
              rows='4'
              style={{
                width: '100%',
                border: '1px solid #ddd',
                borderRadius: '5px',
                padding: '10px',
                marginBottom: '5px',
              }}
            ></textarea>
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              margin: 10,
            }}
          >
            {selectedData?.isApproved ? (
              <Button
                type='primary'
                disabled={!selectedVenue || selectedData?.isApproved}
                onClick={() => {
                  handleSaveChanges(false)
                }}
              >
                Order is already approved
              </Button>
            ) : (
              <>
                <Button
                  type='primary'
                  loading={editLoading}
                  disabled={!selectedVenue || selectedData?.isApproved}
                  onClick={() => {
                    handleSaveChanges(false)
                  }}
                >
                  Save Changes for later
                </Button>
                <Button
                  type='primary'
                  loading={approveLoading}
                  disabled={!selectedVenue || selectedData?.isApproved}
                  onClick={() => {
                    handleSaveChanges(true)
                  }}
                >
                  Save Changes & Accept Order
                </Button>
              </>
            )}
          </div>
        </Card>
      ) : (
        <Card>
          <h3>Edit Order Details</h3>
          <h4>Venue (required)</h4>
          <AutoComplete
            showSearch
            value={selectedVenue}
            onSelect={value => {
              setVenueSuppliers([])
              setSupplierProducts([])
              const data = customerVenues.find(venue => venue._id === value)
              setSelectedVenue(data?.title || data?.venueTitle)
              handleVenueChange(value)
            }}
            placeholder='Search venues'
            style={{ width: '100%' }}
          >
            {customerVenues?.map(venue => (
              <Option key={venue?._id} value={venue?._id}>
                {venue?.title || venue?.venueTitle}
              </Option>
            ))}
          </AutoComplete>
          <h4 style={{ marginTop: 10 }}>Assigned Price File</h4>
          {selectedVenue && venueSuppliers && (
            <Input style={{ marginBottom: 20 }} value={supplierTitle} />
          )}
          <FormItem>
            <label>Delivery Date</label>
            <Input
              type='date'
              value={dayjs(editedOrder?.deliveryDate).format('YYYY-MM-DD')}
              onChange={e => handleChangeDeliveryDate(e.target.value)}
            />
          </FormItem>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              margin: 10,
            }}
          >
            <h3>Products</h3>
            <Button
              disabled={!supplierProducts.length}
              onClick={() => setAddProduct(true)}
              style={{ marginTop: 5, marginBottom: 20 }}
              type={'primary'}
            >
              Add product
            </Button>
          </div>
          {newProducts.map((product, index) => (
            <AddProduct
              key={product.id ?? product._id}
              supplierProducts={supplierProducts}
              handleAddProduct={handleAddProduct}
              handleRemoveProduct={handleRemoveProduct}
              newProducts={newProducts}
              product={product}
              index={index}
            />
          ))}

          {addProduct && (
            <AddProduct
              supplierProducts={supplierProducts}
              handleAddProduct={handleAddProduct}
              handleRemoveProduct={handleRemoveProduct}
              newProducts={newProducts}
            />
          )}
          <div>
            <h4>Add Notes:</h4>
            <textarea
              onChange={e => setFeddback(e.target.value)}
              placeholder='Write any comments or notes here...'
              rows='4'
              style={{
                width: '100%',
                border: '1px solid #ddd',
                borderRadius: '5px',
                padding: '10px',
                marginBottom: '5px',
              }}
            ></textarea>
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              margin: 10,
            }}
          >
            {selectedData?.isApproved ? (
              <Button
                type='primary'
                disabled={!selectedVenue || selectedData?.isApproved}
                onClick={() => {
                  handleSaveChanges(false)
                }}
              >
                Order is already approved
              </Button>
            ) : (
              <>
                <Button
                  type='primary'
                  loading={editLoading}
                  disabled={!selectedVenue || selectedData?.isApproved}
                  onClick={() => {
                    handleSaveChanges(false)
                  }}
                >
                  Save Changes for later
                </Button>
                <Button
                  key='save'
                  type='primary'
                  loading={approveLoading}
                  disabled={!selectedVenue || selectedData?.isApproved}
                  onClick={() => {
                    handleSaveChanges(true)
                  }}
                >
                  Save Changes & Accept Order
                </Button>
              </>
            )}
          </div>
        </Card>
      )}
    </div>
  )
}

const Card = styled.div`
  width: 80%;
  margin-top: 50px;
  margin-left: 50px;
`

const FormItem = styled.div`
  margin-bottom: 15px;

  label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
  }

  input {
    width: 100%;
    padding: 8px;
    font-size: 14px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
`

export default EditOrderScreen
