import { createSlice } from '@reduxjs/toolkit'
import { API, Auth } from 'aws-amplify'
import { editProductProduct as editVenueProduct } from 'src/features/VenueProductsSlice'
import { SuccessNotification } from 'src/notifications/SuccessNotification'
import Sentry from 'src/utils/sentry'

const productSlice = createSlice({
  name: 'product',
  initialState: {
    products: [],
    productStats: {
      serverCount: 0,
    },
  },
  reducers: {
    setProducts: (state, action) => {
      state.products = action.payload
    },
    setProductStats: (state, action) => {
      state.productStats.serverCount = action.payload.count
    },
    addProduct: (state, action) => {
      state.products.push(action.payload)
    },
    addProducts: (state, action) => {
      state.products = state.products.concat(action.payload)
    },
    deleteProduct: (state, action) => {
      state.products = state.products.filter(
        item => item._id !== action.payload,
      )
    },
    editProduct: (state, action) => {
      const index = state.products.findIndex(
        product => product._id === action.payload._id,
      )
      if (index !== -1) state.products[index] = action.payload
    },
  },
})

export const {
  setProducts,
  setProductStats,
  addProduct,
  addProducts,
  deleteProduct,
  editProduct,
} = productSlice.actions

export const adminFetchProducts =
  ({ page, limit, onSuccess, onFailure }) =>
  async dispatch => {
    try {
      const session = await Auth.currentAuthenticatedUser()
      const { products, count } = await API.get(
        'api',
        `products?page=${page}&limit=${limit}&username=${session.attributes.sub}`,
      )
      dispatch(setProducts(products))
      dispatch(setProductStats({ count }))
      if (onSuccess) onSuccess()
    } catch (e) {
      console.error(e)
      Sentry.captureException(e)
      if (onFailure) onFailure()
      return false
    }
  }

export const adminAddProduct = product => async dispatch => {
  try {
    const _product = await API.post('api', `product/add-new`, {
      body: product,
    })
    dispatch(addProduct(_product))
  } catch (e) {
    console.error(e)
    Sentry.captureException(e)
    return false
  }
}

export const adminEditProduct = product => async dispatch => {
  try {
    const _product = await API.post('api', `product`, {
      body: product,
    })
    dispatch(editProduct(_product))
  } catch (e) {
    console.error(e)
    Sentry.captureException(e)
    return false
  }
}

export const adminDeleteProduct = product => async dispatch => {
  try {
    await API.del('api', `product/${product._id}`)
    dispatch(deleteProduct(product._id))
  } catch (e) {
    console.error(e)
    Sentry.captureException(e)
    return false
  }
}

export const uploadProductsCSV =
  (data, selectedSupplier, callback, setMessage) => async () => {
    try {
      setMessage('Uploading CSV')
      const filteredData = data.map(item => {
        return {
          ...item,
          price: item?.price ? item.price : item.CustomerPrice,
          OMSPrice: item?.OMSPrice ? item.OMSPrice : item.SupplierPrice,
        }
      })
      await API.post('api', `admin/products-csv`, {
        body: { items: filteredData, selectedSupplier },
      })
    } catch (e) {
      console.error(e)
      Sentry.captureException(e)
      setMessage('Upload CSV')
      callback && callback()
      return false
    }

    try {
      setMessage('Rebuilding search index')
      await API.get('api', `admin/search/indexProducts/${selectedSupplier}`)
      setMessage('Upload CSV')
      callback && callback()
      window.location.reload()
    } catch (e) {
      console.error(e)
      Sentry.captureException(e)
      alert(`WARNING
    
Your products were uploaded to the database successfully, but the search index could not be rebuilt.
    
Newly-created products will not yet appear in a search.`)
      setMessage('Upload CSV')
      callback && callback()
      window.location.reload()
    }
  }

export const uploadProductsAllergensCSV = (data, callback) => async () => {
  try {
    // setMessage('Uploading CSV')
    await API.post('api2', `admin/products-csv-allergens`, {
      body: { items: data },
    })
    callback && callback()
    SuccessNotification({
      message:
        'Allergens CSV has been uploaded and allergens added to products',
    })
  } catch (e) {
    console.error(e)
    Sentry.captureException(e)
    // setMessage('Upload CSV')
    callback && callback()
    return false
  }
}

export const uploadProductsCSVOnRequiredDate =
  (data, selectedSupplier, callback, setMessage) => async () => {
    try {
      setMessage('Uploading CSV to S3')

      await API.post('api', `admin/products-csv-to-s3`, {
        body: { items: data, selectedSupplier },
      })
      callback && callback()
      window.location.reload()
    } catch (e) {
      console.error(e)
      Sentry.captureException(e)
      alert('An error occurred on the server-side. Could not upload CSV.')
      setMessage('Upload CSV')
      callback && callback()
      return false
    }
  }

export const clearPriceNotification =
  (product, accountId) => async dispatch => {
    try {
      const _product = await API.post('api', `product/clear-notification`, {
        body: { product_id: product._id, account_id: accountId },
      })
      dispatch(editVenueProduct(_product))
    } catch (e) {
      console.error(e)
      Sentry.captureException(e)
      return false
    }
  }

export default productSlice
