import { Checkbox, Input, Select } from 'antd'
import React from 'react'
import styled from 'styled-components/macro'

const EditProductModal = ({ product, setEditedProduct }) => {
  return (
    <Wrapper>
      <Row>
        <InputComp
          value={product.title}
          onChange={e => {
            setEditedProduct({ ...product, title: e.target.value })
          }}
          addonBefore={'Title'}
          placeholder='Title'
        />
      </Row>
      <Row>
        <InputComp
          value={product.size}
          onChange={e => {
            setEditedProduct({ ...product, size: e.target.value })
          }}
          addonBefore={'Size'}
          placeholder='Size'
        />
      </Row>
      <Row>
        <InputComp
          value={product.price}
          addonBefore={'Customer Price'}
          onChange={e => {
            setEditedProduct({ ...product, price: e.target.value })
          }}
          placeholder='Customer Price'
        />
      </Row>
      <Row>
        <InputComp
          value={product.OMSPrice}
          addonBefore={'OMS Price'}
          onChange={e => {
            setEditedProduct({ ...product, OMSPrice: e.target.value })
          }}
          placeholder='OMS Price'
        />
      </Row>
      <Row>
        <Div>
          <Span>UoM</Span>
          <Select
            addonBefore={'UoM'}
            allowClear
            style={{ width: '88%' }}
            placeholder='Unit of measure'
            value={product.UoM}
            onChange={value => {
              setEditedProduct({ ...product, UoM: value })
            }}
          >
            <Select.Option key='Kg'>Kg</Select.Option>
            <Select.Option key='Each'>Each</Select.Option>
            <Select.Option key='Pack'>Pack</Select.Option>
            <Select.Option key='Box'>Box</Select.Option>
          </Select>
        </Div>
      </Row>
      <Row>
        <Div>
          <Span>Priced by</Span>
          <Select
            addonBefore={'Priced by'}
            allowClear
            style={{ width: '81%' }}
            placeholder='Priced by per...'
            value={product.pricedBy || product.UoM}
            onChange={value => {
              setEditedProduct({ ...product, pricedBy: value })
            }}
          >
            <Select.Option key='Kg'>Kg</Select.Option>
            <Select.Option key='Each'>Each</Select.Option>
            <Select.Option key='Pack'>Pack</Select.Option>
            <Select.Option key='Box'>Box</Select.Option>
          </Select>
        </Div>
      </Row>
      <Row>
        <Div>
          <Span>Price File</Span>
          <InputComp
            value={
              product.supplier
                ? product.supplier.secondTitle
                  ? product.supplier.title + ' ' + product.supplier.secondTitle
                  : product.supplier.title
                : null
            }
            style={{ width: '70%' }}
          />
        </Div>
      </Row>
      <Row>
        <InputComp
          value={product.sku}
          addonBefore={'SKU'}
          onChange={e => {
            setEditedProduct({ ...product, sku: e.target.value })
          }}
          placeholder='SKU'
        />
      </Row>
      <Row>
        <InputComp
          value={product.GLN}
          addonBefore={'GLN'}
          onChange={e => {
            setEditedProduct({ ...product, GLN: e.target.value })
          }}
          placeholder='GLN'
        />
      </Row>
      <Row>
        <InputComp
          value={product.type}
          addonBefore={'Type'}
          onChange={e => {
            setEditedProduct({ ...product, type: e.target.value })
          }}
          placeholder='Type'
        />
      </Row>
      <Row>
        <InputComp
          value={product.cloudinaryImageId}
          addonBefore={'Image'}
          onChange={e => {
            const value = e.target.value
            const regexMatch = value.match(
              /https:\/\/res.cloudinary.com\/ordersmadesimple\/image\/upload\/(?:v\d+\/)?(.+)\./i,
            )
            const id = regexMatch?.[1] || value
            setEditedProduct({ ...product, cloudinaryImageId: id })
          }}
          placeholder='Paste in an image URL'
        />
      </Row>
      <Row>
        <CheckboxWrapper>
          <span>Has VAT?</span>
          <Checkbox
            checked={product.hasVat}
            onChange={e => {
              setEditedProduct({ ...product, hasVat: e.target.checked })
            }}
            placeholder='Has Vat'
          />
        </CheckboxWrapper>
      </Row>
    </Wrapper>
  )
}

const Wrapper = styled.div``

const InputComp = styled(Input)``

const CheckboxWrapper = styled.div`
  span {
    margin-right: 10px;
  }
  .ant-checkbox-checked:after {
    border: none;
  }
`

const Row = styled.div`
  margin: 15px 0;
`
const Span = styled.span`
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  padding: 4px 10px;
  max-height: 32px;
  border: 1px solid ${({ theme }) => theme.colours.panSilver};
  background-color: ${({ theme }) => theme.colours.bgGrey};
`
const Div = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`
export default EditProductModal
