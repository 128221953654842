import { SearchOutlined } from '@ant-design/icons'
import { Checkbox, Col, Input, Row } from 'antd'
import React, { useContext, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import LoadingButton from 'src/components/styled/LoadingButton'
import {
  performSearch,
  setError,
  setFields,
  setPage,
  setProducts,
  setTerm,
  setTotal,
} from 'src/features/CatalogueSearchSlice'
import { resetCatalougeFilter } from 'src/features/FilterSlice'
import { useWindowWidth } from 'src/utils/useWindowWidth'
import styled, { ThemeContext } from 'styled-components/macro'

const SearchBar = ({ venueId }) => {
  const dispatch = useDispatch()
  const searchFields = useSelector(state => state.catalogueSearch.fields)
  const searchTerm = useSelector(state => state.catalogueSearch.term)
  const searchProducts = useSelector(state => state.catalogueSearch.products)
  const pageSize = useSelector(state => state.catalogueSearch.pageSize)
  const searchTotal = useSelector(state => state.catalogueSearch.total)
  const searchLoading = useSelector(state => state.catalogueSearch.loading)
  const searchError = useSelector(state => state.catalogueSearch.error)
  const supplierFilterName = useSelector(
    state => state.filter.catalogue.supplier,
  )

  const onSearch = () => {
    if (!searchTerm.trim()) {
      dispatch(setError('Please enter a search term'))
      dispatch(setTerm(''))
    } else {
      dispatch(performSearch({ venueId, pageNumber: 1, pageSize }))
    }
  }
  useEffect(() => {
    if (supplierFilterName && supplierFilterName.length > 0) {
      dispatch(setTerm(supplierFilterName[0]))
      dispatch(resetCatalougeFilter())
      dispatch(setFields(['supplierTitle']))
      dispatch(performSearch({ venueId, pageNumber: 1, pageSize }))
      dispatch(setError(''))
    }
  }, [])

  const windowWidth = useWindowWidth()
  const theme = useContext(ThemeContext)
  const isMobile = windowWidth <= theme.breakpoints.lg
  const isSupplierPortal = window.location.href.endsWith('supplier/products')

  return (
    <Input.Group size='large' style={{ width: '100%', marginBottom: 20 }}>
      <Row gutter={16}>
        <Col span={isMobile ? 24 : 8}>
          <Input
            prefix={<SearchOutlined />}
            placeholder='Search by...'
            value={searchTerm}
            onChange={event => {
              dispatch(setError(''))
              if (!event.target.value) {
                dispatch(setTotal(0))
                dispatch(setPage(1))
                dispatch(setProducts(null))
              }
              dispatch(setTerm(event.target.value))
            }}
            onPressEnter={onSearch}
            allowClear
          />
          {searchError && <SearchError>{searchError}</SearchError>}
        </Col>
        <Col
          span={isMobile ? 24 : 6}
          style={{
            minHeight: '40px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Checkbox.Group
            options={
              isSupplierPortal
                ? [
                    { label: 'Title', value: 'title' },
                    { label: 'SKU', value: 'sku' },
                  ]
                : [
                    { label: 'Title', value: 'title' },
                    { label: 'SKU', value: 'sku' },
                    { label: 'Supplier', value: 'supplierTitle' },
                  ]
            }
            value={searchFields}
            onChange={checkedValues => dispatch(setFields(checkedValues))}
          />
        </Col>
        <Col span={isMobile ? 12 : 2}>
          <LoadingButton
            type='primary'
            size='large'
            loading={searchLoading}
            onClick={onSearch}
          >
            Search
          </LoadingButton>
        </Col>
        <Col span={isMobile ? 12 : 8}>
          {Boolean(searchTerm.trim()) && Boolean(searchProducts) && (
            <SearchResultCount>
              {searchTotal}
              {searchTotal === 1 ? ' result' : ' results'}
            </SearchResultCount>
          )}
        </Col>
      </Row>
    </Input.Group>
  )
}

const SearchResultCount = styled.p`
  min-height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  text-align: right;
`

const SearchError = styled.p`
  color: ${({ theme }) => theme.colours.chilliRed};
`

export default SearchBar
