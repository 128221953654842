import { useMemo } from 'react'
import { Select } from 'antd'
import { useSelector } from 'react-redux'

const { Option } = Select

const SearchableSelect = ({
  value,
  onChange,
  products,
  type = 'sku',
  placeholder = 'Search',
  style = { width: 300 },
}) => {
  const productsFromState = useSelector(state => state.menus.products)
  const productsToUse = products || productsFromState || []
  // console.log('productsToUse -----> ', productsToUse)

  const options = useMemo(
    () =>
      productsToUse.map(item => ({
        value: item.product[type],
        label: item.product.sku + ' - ' + item.product.title,
        sku: item.product.sku,
        supplier: item.supplierTitle,
        price: item.product.price,
        size: item.product.size,
        id: item._id,
        title: item.product.title,
      })),
    [productsToUse],
  )

  const handleChange = (value, option) => {
    if (onChange) {
      onChange(value, option)
    }
  }

  return (
    <Select
      showSearch
      value={value}
      placeholder={placeholder}
      optionFilterProp='children'
      onChange={handleChange}
      filterOption={(input, option) => {
        const text =
          typeof option.props.children === 'string'
            ? option.props.children
            : option.props.label

        return text.toLowerCase().includes(input.toLowerCase())
      }}
      style={style}
      dropdownStyle={{
        minWidth: '320px',
        padding: '8px',
      }}
      listHeight={400}
      optionHeight={50}
    >
      {options.map(option => (
        <Option key={option.id} value={option.id} label={option.label}>
          {type === 'sku' ? (
            option.label
          ) : (
            <div
              style={{
                padding: '8px',
                borderRadius: '5px',
                background: '#f8f8f8',
                marginBottom: '5px',
                boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.1)',
              }}
            >
              <strong>Product:</strong> {option.title} <br />
              <strong>Supplier:</strong> {option.supplier} <br />
              <strong>Price:</strong> £{option.price} <br />
              <strong>Size:</strong> {option.size}
            </div>
          )}
        </Option>
      ))}
    </Select>
  )
}

export default SearchableSelect
