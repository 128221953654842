import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Input, AutoComplete, Typography } from 'antd'
import { DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import { deleteOrder } from 'src/features/OrdersCaptureSlice'

const { Option } = AutoComplete
const { Text } = Typography

const isProductInSupplierList = (supplierProducts, productId) => {
  const isExist = supplierProducts?.find(product => product._id === productId)
  return isExist
}

const ProductCard = ({
  order,
  index,
  handleOmsOrderChange,
  supplierProducts,
  editedOmsOrders,
  setEditedOmsOrders,
  selectedData,
  handleOmsProductChange,
  setSelectedData,
}) => {
  const [selectedProduct, setSelectedProduct] = useState(order.product)
  const [filteredProducts, setFilteredProducts] = useState([])
  const [search, setSearch] = useState({
    sku: null,
    title: null,
  })
  const deleteProduct = async index => {
    const orderId = editedOmsOrders[index]?._id
    if (!orderId) return
    // Remove the orderId from selectedData.order.orderIds
    const updatedOrderIds = selectedData?.order?.orderIds?.filter(
      id => id !== orderId,
    )

    // Update selectedData state if needed
    if (selectedData?.order) {
      setSelectedData(prev => ({
        ...prev,
        order: {
          ...prev.order,
          orderIds: updatedOrderIds,
        },
      }))
    }

    // Call API to delete order
    await deleteOrder(selectedData?.order?._id, orderId)
    const newOrders = editedOmsOrders.filter(order => order._id !== orderId)
    setEditedOmsOrders(newOrders)
  }

  const productNotFoundWarning =
    supplierProducts?.length > 0 &&
    selectedProduct?._id &&
    !isProductInSupplierList(supplierProducts, selectedProduct?._id) ? (
      <Text type='danger'>
        <ExclamationCircleOutlined /> {selectedProduct.title} Product is not in
        the price file of the customer
      </Text>
    ) : null

  const handleProductChange = value => {
    const product = supplierProducts?.find(item => item._id === value)
    setSearch(preSearch => ({
      ...preSearch,
      title: product.title,
      sku: product.sku,
    }))
    if (product) {
      const updatedOrder = {
        ...order,
        product,
        title: product.title,
        price: product.price,
      }
      setSelectedProduct(product)
      handleOmsProductChange(index, updatedOrder)
    }
  }

  useEffect(() => {
    if (supplierProducts) {
      setFilteredProducts(supplierProducts)
    }
  }, [supplierProducts])
  return (
    <Card>
      <Row>
        <Column>
          <label>SKU</label>
          {supplierProducts ? (
            <>
              <AutoComplete
                showSearch
                value={search.sku ?? (selectedProduct.sku || '')}
                onSearch={value => {
                  const products = supplierProducts.filter(product =>
                    product?.sku?.toLowerCase().includes(value.toLowerCase()),
                  )
                  setSearch(preSearch => ({ ...preSearch, sku: value }))
                  setFilteredProducts(products)
                }}
                onSelect={handleProductChange}
                placeholder='Search supplier products'
                style={{ width: '100%' }}
              >
                {filteredProducts?.map(product => (
                  <Option key={product._id} value={product._id}>
                    {product.sku}
                  </Option>
                ))}
              </AutoComplete>
            </>
          ) : (
            <Input
              type='text'
              value={
                selectedProduct?.sku || selectedProduct?.product?.sku || ''
              }
            />
          )}
        </Column>
        <Column style={{ flex: 3 }}>
          <label>Title</label>
          {supplierProducts ? (
            <AutoComplete
              showSearch
              value={search.title ?? (selectedProduct?.title || '')}
              onSearch={value => {
                const products = supplierProducts.filter(product =>
                  product.title.toLowerCase().includes(value.toLowerCase()),
                )
                setFilteredProducts(products)
                setSearch(preSearch => ({ ...preSearch, title: value }))
              }}
              onSelect={handleProductChange}
              placeholder='Search supplier products'
              style={{ width: '100%' }}
            >
              {filteredProducts?.map(product => (
                <Option key={product._id} value={product._id}>
                  {product.title}
                </Option>
              ))}
            </AutoComplete>
          ) : (
            <Input
              type='text'
              value={
                selectedProduct?.title || selectedProduct?.product?.title || ''
              }
            />
          )}
        </Column>

        <Column style={{ flex: 1 }}>
          <label>Quantity</label>
          <Input
            type='number'
            value={order?.quantity || ''}
            onChange={e =>
              handleOmsOrderChange(index, 'quantity', Number(e.target.value))
            }
          />
        </Column>

        <Column style={{ flex: 1 }}>
          <label>Price</label>
          <Input
            type='number'
            value={
              selectedProduct?.price || selectedProduct?.product?.price || ''
            }
          />
        </Column>

        <Column style={{ flex: 0.5, textAlign: 'center' }}>
          <DeleteOutlined
            onClick={() => deleteProduct(index)}
            style={{ color: 'red', fontSize: '20px', cursor: 'pointer' }}
          />
        </Column>
      </Row>
      {supplierProducts?.length ? productNotFoundWarning : ''}
    </Card>
  )
}

const Card = styled.div`
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 10px;
  margin-bottom: 10px;
  background: #f9f9f9;
`

const Row = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
  label {
    font-size: 12px;
    font-weight: bold;
    margin-bottom: 3px;
  }
  input {
    width: 100%;
    padding: 6px;
    font-size: 14px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
`

export default ProductCard
