import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { EyeOutlined, EditOutlined } from '@ant-design/icons'
import { useDispatch, useSelector } from 'react-redux'
import {
  approveOrder,
  fetchAIOrdersData,
  getOrdersDetails,
  parseOrder,
  rejectOrder,
  setOMSOrders,
  // setOMSOrders,
  setParsedOrder,
  setShowMessage,
} from 'src/features/OrdersCaptureSlice'
import Loading from 'src/components/styled/Loading'
import { Button } from 'antd'
import OriginalOrderModal from './OriginalOrderModal'
// import { SuccessNotification } from 'src/notifications/SuccessNotification'
import CopilotIcon from 'src/components/icons/CopilotIcon'
import { Link } from 'react-router-dom'
import { Typography } from 'antd'
const { Text } = Typography
import { ExclamationCircleOutlined } from '@ant-design/icons'
import TrackingOverview from './components/TrackingOverview'
import { SuccessNotification } from 'src/notifications/SuccessNotification'

const CoPilotInterpretation = ({ omsOrders, parsedOrder }) => {
  return (
    <>
      {parsedOrder?.order?.orderIds?.length !== 0 && omsOrders.length !== 0 ? (
        <div style={{ fontSize: '14px' }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <div>
              <p>Venue Name: {omsOrders[0]?.venue?.title}</p>
              <p>Customer Name: {parsedOrder?.order?.customer?.name}</p>
              <p>Customer Email: {parsedOrder?.order?.customer?.email}</p>
              <p>Supplier Name: {omsOrders[0]?.supplier?.title}</p>
              <p>
                Order Date:{' '}
                {new Date(omsOrders[0]?.orderedDate).toLocaleDateString()}
              </p>
              <p>
                Delivery Date:{' '}
                {new Date(
                  omsOrders[0]?.requiredDeliveryDay,
                ).toLocaleDateString() || 'Next Available Delivery Day'}
              </p>
            </div>
            <EditOutlined
              style={{
                fontSize: '20px',
                color: '#007bff',
                cursor: 'pointer',
                marginBottom: '180px',
              }}
              onClick={() => {
                const orderId = parsedOrder?._id
                if (orderId) {
                  window.open(
                    `/supplier/co-pilot/${orderId}/editOrder`,
                    '_blank',
                  )
                }
              }}
            />
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              borderBottom: '1px solid #ddd',
              marginBottom: '15px',
            }}
          ></div>
          <table style={{ width: '100%', borderCollapse: 'collapse' }}>
            <thead style={{ fontSize: '14px' }}>
              <tr>
                <th>Product</th>
                <th>Quantity</th>
                <th>Price</th>
                <th>Total</th>
                <th>Confidence Score</th>
              </tr>
            </thead>
            <tbody style={{ marginTop: '10px' }}>
              {omsOrders?.map((order, index) => (
                <tr key={index} style={{ fontSize: '14px' }}>
                  <td>{order?.title || order?.product?.title}</td>
                  <td>{order?.quantity}</td>
                  <td>
                    £
                    {order?.price && order.price != 0 && order.price != 0.0
                      ? order.price
                      : order?.product?.price || 0}
                  </td>
                  <td>
                    £
                    {(
                      (order?.price && order.price != 0 && order.price != 0.0
                        ? order.price
                        : order?.product?.price || 0) * order?.quantity
                    ).toFixed(2)}
                  </td>
                  {order?.aiConfidenceScore && (
                    <td>{order?.aiConfidenceScore || ''}%</td>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <div style={{ fontSize: '14px' }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <div>
              {!parsedOrder?.order?.venue?.title && (
                <Text type='danger'>
                  <ExclamationCircleOutlined /> Venue Not Recognised
                </Text>
              )}
              <p>
                Venue Name:{' '}
                {parsedOrder?.order?.venue?.title ||
                  parsedOrder?.order?.venueName}
              </p>
              <p>Customer Name: {parsedOrder?.order?.customer?.name}</p>
              <p>Customer Email: {parsedOrder?.order?.customer?.email}</p>
              <p>
                Order Date:{' '}
                {new Date(
                  parsedOrder?.order?.order?.order_date,
                ).toLocaleDateString()}
              </p>
              <p>
                Delivery Date:{' '}
                {new Date(
                  parsedOrder?.order?.order?.delivery_date,
                ).toLocaleDateString() || 'Next Available Delivery Day'}
              </p>
            </div>
            <EditOutlined
              style={{
                fontSize: '20px',
                color: '#007bff',
                cursor: 'pointer',
                marginBottom: '180px',
              }}
              onClick={() => {
                const orderId = parsedOrder?._id
                if (orderId) {
                  window.open(
                    `/supplier/co-pilot/${orderId}/editOrder`,
                    '_blank',
                  )
                }
              }}
            />
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              borderBottom: '1px solid #ddd',
              marginBottom: '15px',
            }}
          ></div>
          <table style={{ width: '100%', borderCollapse: 'collapse' }}>
            <thead style={{ fontSize: '14px' }}>
              <tr>
                <th>Product</th>
                <th>Quantity</th>
                <th>Price</th>
                <th>Total</th>
                <th>Confidence Score</th>
              </tr>
            </thead>
            <tbody style={{ marginTop: '10px' }}>
              {parsedOrder?.order?.order?.products?.map((product, index) => (
                <tr key={index} style={{ fontSize: '14px' }}>
                  <td>{product?.title}</td>
                  <td>{product?.quantity}</td>
                  <td>£{product?.price?.toFixed(2)}</td>
                  <td>£{product?.total?.toFixed(2)}</td>
                  <td>{product?.confidence * 100}%</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </>
  )
}

const OMSCopilot = () => {
  const username = useSelector(state => state.auth.cognito.username)
  const AIOrderCaptureEmail = useSelector(
    state => state.auth.account.AIOrderCaptureEmail,
  )
  const accountId = useSelector(state => state.auth.account._id)
  const {
    isLoading,
    showMessage,
    rejectLoading,
    approveLoading,
    omsOrders,
    emails,
    parsedOrders,
    parsedOrder,
    correctOrderRate,
    averageConfidenceScore,
    parssedLoading,
    trackingOverview,
    // editLoading
  } = useSelector(state => state.ordersCapture)

  const dispatch = useDispatch()
  const [visibleOriginalOrderModal, setVisibleOriginalOrderModal] =
    useState(false)
  const [feedback, setFeddback] = useState('')

  // Fetch data on mount
  useEffect(() => {
    dispatch(fetchAIOrdersData(username))
  }, [dispatch, username])

  // Parse order and fetch order details
  useEffect(() => {
    if (parsedOrder && parsedOrder.order === null) {
      dispatch(parseOrder(accountId, parsedOrder?._id, parsedOrder.email?._id))
    }
    if (
      parsedOrder &&
      parsedOrder?.order?.orderIds?.length >= 1 &&
      parsedOrder?.order?.orderIds !== omsOrders?.map(order => order._id)
    ) {
      dispatch(getOrdersDetails(parsedOrder?.order?.orderIds))
    }
  }, [parsedOrder, dispatch, accountId])

  // Handle email card click
  const handleEmailCardClick = email => {
    console.log('email', email)
    if (parsedOrder?.email?._id === email?._id) return
    const data = parsedOrders.find(order => order.email?._id === email?._id)

    console.log('callinng setOMSorders')
    dispatch(setOMSOrders([]))
    dispatch(setParsedOrder(data || null))
  }
  console.log('omsOrders', omsOrders)

  useEffect(() => {
    if (!approveLoading && !rejectLoading && showMessage) {
      SuccessNotification({ message: showMessage })
      dispatch(fetchAIOrdersData(username))
      dispatch(setShowMessage(null))
    }
  }, [showMessage, approveLoading, rejectLoading])

  return (
    <Wrapper style={{ height: '100%' }}>
      <OriginalOrderModal
        email={parsedOrder?.email}
        visibleOriginalOrderModal={visibleOriginalOrderModal}
        setVisibleOriginalOrderModal={setVisibleOriginalOrderModal}
      />
      <Header>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <CopilotIcon size={50} />
          <h1
            style={{
              fontSize: '32px',
              marginLeft: '12px',
            }}
          >
            OMS Co-Pilot
          </h1>
        </div>

        <p
          style={{
            color: '#555',
            fontSize: '18px',
            marginBottom: '10px',
            padding: ' 10px',
            lineHeight: '1.5',
            width: '90%',
          }}
        >
          Co-pilot automatically processes incoming email orders. The system
          reads orders, presents them as a checklist for review, and lets you
          make changes if needed. Once accepted, the order is sent to your
          system and stored in OMS as usual. Simple, fast, and efficient.
        </p>
        <ForwardEmail>
          Forward Your Orders To: <span>{AIOrderCaptureEmail}</span>
        </ForwardEmail>
        <TrackingOverview trackingOverview={trackingOverview} />
      </Header>

      <Metrics>
        <MetricCard>
          <div>
            <h4>{averageConfidenceScore}%</h4>
            <p style={{ color: '#555' }}> Average Confidence Score</p>
          </div>
        </MetricCard>
        <MetricCard>
          <div>
            <h4>{correctOrderRate}%</h4>
            <p style={{ color: '#555' }}>Correct Order Rate</p>
          </div>
        </MetricCard>
      </Metrics>

      <Button type={'primary'} style={{ margin: 20, cursor: 'pointer' }}>
        <Link to={`/supplier/orders`}>Approved Orders</Link>
      </Button>
      {isLoading ? (
        <Loading />
      ) : parsedOrders?.length ? (
        <Content>
          <Section>
            <h3> New Orders </h3>
            <ScrollableOrderContainer>
              {emails?.map((email, index) => (
                <EmailCard
                  key={email?._id || index}
                  isSelected={parsedOrder?.email?._id === email?._id}
                  onClick={() => handleEmailCardClick(email)}
                >
                  <p>
                    <strong>Subject:</strong> {email?.subject}
                  </p>
                  <p>
                    <strong>From:</strong> {email?.from?.senderEmail}
                  </p>
                  <p>
                    <strong>Date:</strong>{' '}
                    {new Date(email?.date).toLocaleString()}
                  </p>
                  <EmailBody
                    dangerouslySetInnerHTML={{
                      __html: email?.body.slice(0, 150),
                    }}
                  />
                  <div
                    onClick={() => {
                      setVisibleOriginalOrderModal(true)
                    }}
                    style={{ display: 'flex', marginTop: 10 }}
                  >
                    <p
                      style={{
                        fontSize: '12px',
                        color: '#b5d922',
                        cursor: 'pointer',
                        margin: 5,
                      }}
                    >
                      View Original Order:
                    </p>
                    <EyeOutlined
                      style={{
                        fontSize: '20px',
                        color: '#b5d922',
                        cursor: 'pointer',
                        margin: 5,
                      }}
                    />
                  </div>
                </EmailCard>
              ))}
            </ScrollableOrderContainer>
          </Section>
          <Section isWide>
            <h3>Co-Pilot Interpretation </h3>
            <Card>
              {parssedLoading ? (
                <Loading />
              ) : (
                <CoPilotInterpretation
                  parssedLoading={parssedLoading}
                  omsOrders={omsOrders}
                  parsedOrder={parsedOrder}
                />
              )}
            </Card>
          </Section>

          <Section>
            <h3>Review and Finalize</h3>
            <Card>
              <div>
                <h4>Add Notes:</h4>
                <textarea
                  onChange={e => setFeddback(e.target.value)}
                  placeholder='Write any comments or notes here...'
                  rows='4'
                  style={{
                    width: '100%',
                    border: '1px solid #ddd',
                    borderRadius: '5px',
                    padding: '10px',
                    marginBottom: '15px',
                  }}
                ></textarea>
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Button
                  loading={approveLoading}
                  style={{
                    backgroundColor: '#b5d922',
                    color: '#fff',
                    margin: 5,
                    border: 'none',
                    borderRadius: '5px',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    dispatch(approveOrder(parsedOrder?._id, feedback))
                    setFeddback('')
                  }}
                >
                  Approve Order
                </Button>
                <Button
                  loading={rejectLoading}
                  style={{
                    backgroundColor: '#dc3545',
                    color: '#fff',
                    margin: 5,
                    border: 'none',
                    borderRadius: '5px',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    const orderId = parsedOrder?.order?._id || parsedOrder?._id
                    dispatch(rejectOrder(orderId, feedback))
                    setFeddback('')
                  }}
                >
                  Reject Order
                </Button>
              </div>
            </Card>
          </Section>
        </Content>
      ) : (
        <div
          style={{
            display: 'flex',
            marginTop: 200,
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <h2
            style={{
              color: '#b5d922',
            }}
          >
            No Data
          </h2>
        </div>
      )}
    </Wrapper>
  )
}

export default OMSCopilot

const Wrapper = styled.div`
  padding: 20px;
  background-color: #f9f9f9;
`

const Header = styled.div`
  text-align: center;
  margin-bottom: 30px;
  h1 {
    color: ${({ theme }) => theme.colours.lettuceGreen} !important;
    margin: 0;
  }
`

const ForwardEmail = styled.p`
  text-align: center;
  font-size: 19px;
  color: #555;
  span {
    color: ${({ theme }) => theme.colours.lettuceGreen};
    font-weight: bold;
    font-size: 19px;
  }
`

const Metrics = styled.div`
  display: flex;
  justify-content: space-around;
  margin-bottom: 30px;
`

const MetricCard = styled.div`
  background: #ffffff;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;

  .icon {
    font-size: 40px;
    color: #007bff;
  }

  h4 {
    color: ${({ theme }) => theme.colours.lettuceGreen};
    font-size: 20px;
  }
`

const Content = styled.div`
  display: flex;
  gap: 20px;
`

const Section = styled.div`
  flex: ${({ isWide }) => (isWide ? 2 : 1)};
  background: #ffffff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  .section-icon {
    font-size: 24px;
    color: #007bff;
    margin-right: 10px;
  }

  h3 {
    margin-bottom: 20px;
  }
`

const EmailCard = styled.div`
  border: 2px solid ${({ isSelected }) => (isSelected ? '#b5d922' : '#ddd')};
  border-radius: 10px;
  padding: 15px;
  margin-bottom: 10px;
    background-color:  #ffffff; !important
  transition: all 0.3s ease-in-out;
  cursor: pointer;

  &:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  }
`

const EmailBody = styled.div`
  color: #555;
  margin-top: 10px;
  font-size: 14px;
`

const Card = styled.div`
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 20px;
  background: #ffffff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`

const ScrollableOrderContainer = styled.div`
  max-height: 500px;
  overflow-y: auto;
  padding: 10px;
  margin-top: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: ${({ theme }) => theme.colours.panSilver};
`
