import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Input, AutoComplete } from 'antd'
import { DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import Text from 'antd/lib/typography/Text'
// import { DeleteOutlined } from '@ant-design/icons';
// import { deleteOrder } from 'src/features/OrdersCaptureSlice';
const { Option } = AutoComplete

const isProductInSupplierList = (supplierProducts, productId) => {
  const isExist = supplierProducts?.find(product => product._id === productId)
  return isExist
}

const AddProduct = ({
  supplierProducts,
  handleAddProduct,
  handleRemoveProduct,
  newProducts,
  product,
  index,
}) => {
  const [selectedProduct, setSelectedProduct] = useState(product || {})
  const [filteredProducts, setFilteredProducts] = useState([])
  const [search, setSearch] = useState({
    sku: null,
    title: null,
  })
  const [quantity, setQuantity] = useState(
    Number(selectedProduct?.quantity || 1),
  )

  useEffect(() => {
    if (supplierProducts) {
      setFilteredProducts(supplierProducts)
    }
  }, [supplierProducts])

  const productNotFoundWarning =
    supplierProducts?.length > 0 &&
    selectedProduct?._id &&
    !isProductInSupplierList(supplierProducts, selectedProduct?._id) ? (
      <Text type='danger'>
        <ExclamationCircleOutlined /> Product is not in the price file of the
        customer
      </Text>
    ) : null

  return (
    <Card>
      <Row>
        <Column>
          <label>SKU</label>
          {supplierProducts ? (
            <AutoComplete
              showSearch
              value={search.sku ?? (selectedProduct.sku || '')} // Set the value to the selected product's SKU
              notFoundContent={'Product not found'}
              onSearch={value => {
                const products = supplierProducts.filter(product =>
                  product?.sku?.toLowerCase().includes(value.toLowerCase()),
                )
                setSearch(preSearch => ({ ...preSearch, sku: value }))
                setFilteredProducts(products)
                // setSelectedProduct(products[0])
              }}
              onSelect={value => {
                const product = supplierProducts?.find(
                  item => item._id === value,
                )
                const updatedProduct = {
                  ...product,
                  quantity: quantity,
                  id: product._id + Math.random().toString(36).substring(7),
                }

                console.log('updatedProduct---> ', updatedProduct)
                setSearch(preSearch => ({
                  ...preSearch,
                  title: updatedProduct.title,
                  sku: updatedProduct.sku,
                }))
                setSelectedProduct(updatedProduct)
                let products = [...newProducts]
                if (index !== undefined) {
                  products[index] = updatedProduct
                } else {
                  products.push(updatedProduct)
                }
                handleAddProduct(products)
              }}
              placeholder='Search supplier products'
              style={{ width: '100%' }}
            >
              {filteredProducts?.map(product => (
                <Option key={product._id} value={product._id}>
                  {product.sku}
                </Option>
              ))}
            </AutoComplete>
          ) : (
            <Input
              type='text'
              value={
                selectedProduct?.sku || selectedProduct?.product?.sku || ''
              }
            />
          )}
        </Column>
        <Column style={{ flex: 3 }}>
          <label>Title</label>

          {supplierProducts ? (
            <AutoComplete
              showSearch
              value={search.title ?? (selectedProduct?.title || '')} // Set the value to the selected product's title
              notFoundContent={'Product not found'}
              onSearch={value => {
                const products = supplierProducts.filter(product =>
                  product.title.toLowerCase().includes(value.toLowerCase()),
                )
                setFilteredProducts(products)
                setSearch(preSearch => ({ ...preSearch, title: value }))
              }}
              onSelect={value => {
                const product = supplierProducts?.find(
                  item => item._id === value,
                )
                const updatedProduct = {
                  ...product,
                  quantity: quantity,
                  id: product._id + Math.random().toString(36).substring(7),
                }
                console.log('updatedProduct---> ', updatedProduct)
                setSearch(preSearch => ({
                  ...preSearch,
                  title: updatedProduct.title,
                  sku: updatedProduct.sku,
                }))
                setSelectedProduct(updatedProduct)
                let products = [...newProducts]
                if (index !== undefined) {
                  products[index] = updatedProduct
                } else {
                  products.push(updatedProduct)
                }

                handleAddProduct(products)
              }}
              placeholder='Search supplier products'
              style={{ width: '100%' }}
            >
              {filteredProducts?.map(product => (
                <Option key={product._id} value={product._id}>
                  {product.title}
                </Option>
              ))}
            </AutoComplete>
          ) : (
            <Input
              type='text'
              value={
                selectedProduct?.title || selectedProduct?.product?.title || ''
              }
            />
          )}
        </Column>

        <Column style={{ flex: 1 }}>
          <label>Quantity</label>
          <Input
            type='number'
            value={quantity}
            onChange={e => {
              const newQuantity = Number(e.target.value)
              setQuantity(newQuantity)

              // Update the selected product with new quantity
              setSelectedProduct(prevProduct => {
                const updatedProduct = {
                  ...prevProduct,
                  quantity: newQuantity,
                  id: product._id + Math.random().toString(36).substring(7),
                }

                // Update the product in the list
                let products = [...newProducts]
                if (index !== undefined) {
                  products[index] = updatedProduct
                } else {
                  products.push(updatedProduct)
                }

                handleAddProduct(products)

                return updatedProduct
              })
            }}
          />
        </Column>

        <Column style={{ flex: 1 }}>
          <label>Price</label>
          <Input
            type='number'
            value={
              selectedProduct?.price || selectedProduct?.product?.price || ''
            }
          />
        </Column>

        <Column style={{ flex: 0.5, textAlign: 'center' }}>
          {newProducts.some(p => p._id === selectedProduct._id) && (
            <DeleteOutlined
              onClick={() =>
                handleRemoveProduct(selectedProduct?.id ?? selectedProduct?._id)
              }
              style={{ color: 'red', fontSize: '20px', cursor: 'pointer' }}
            />
          )}
        </Column>
      </Row>
      {supplierProducts?.length ? productNotFoundWarning : ''}
    </Card>
  )
}
const Card = styled.div`
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 10px;
  margin-bottom: 10px;
  background: #f9f9f9;
`

const Row = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
  label {
    font-size: 12px;
    font-weight: bold;
    margin-bottom: 3px;
  }
  input {
    width: 100%;
    padding: 6px;
    font-size: 14px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
`
export default AddProduct
