import AntDIcon from 'antd/lib/icon'
import React from 'react'

const Icon = ({ size }) => {
  const SVG = () => (
    <svg
      width={size}
      height={size}
      viewBox='0 0 64 64'
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      stroke='black'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    >
      <rect
        x='10'
        y='18'
        width='44'
        height='28'
        rx='4'
        ry='4'
        fill='none'
        stroke='black'
      />

      <circle cx='22' cy='30' r='4' fill='none' stroke='black' />
      <circle cx='42' cy='30' r='4' fill='none' stroke='black' />

      <rect
        x='24'
        y='36'
        width='16'
        height='4'
        rx='2'
        ry='2'
        fill='none'
        stroke='black'
      />

      <line x1='16' y1='18' x2='16' y2='8' stroke='black' />
      <circle cx='16' cy='6' r='2' fill='none' stroke='black' />

      <line x1='48' y1='18' x2='48' y2='8' stroke='black' />
      <circle cx='48' cy='6' r='2' fill='none' stroke='black' />

      <rect
        x='4'
        y='24'
        width='6'
        height='12'
        rx='2'
        ry='2'
        fill='none'
        stroke='black'
      />
      <rect
        x='54'
        y='24'
        width='6'
        height='12'
        rx='2'
        ry='2'
        fill='none'
        stroke='black'
      />
    </svg>
  )

  return <AntDIcon component={SVG} />
}

export default Icon
